import React , { useRef,  useEffect, useLayoutEffect} from "react";
import { NavLink } from "react-router-dom";
import { Base64 } from "js-base64";
import Swiper, {Navigation} from "swiper";
import { AddData } from "../comands/add";
import { Edit } from "../comands/edit";
import { Delete } from "../comands/delete";
import useGlobal from "../hooks/useAuthContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
// import { Delete } from "../comands/delete";
// import { Edit } from "../comands/edit";

// function useForceUpdate(){
//     const [value, setValue] = useState(0); // integer state
//     return () => setValue(value => value + 1); // update state to force render
// }



function Img(props) {
    return (
        <div className="slide swiper-slide">
            <img src={props.src} className="pub_photo " alt="job" />
        </div>
    )
}

function VideoPublication(props) {
    const pub = props.publication;
    const author = props.author;

    
    return (
        <div className="pub" guid={pub.guid}>
            <div className="pub_title">{pub?.title}</div>
            <div className="pub_photos video" type={pub?.type}>
                <video src={pub?.video} controls></video>
            </div>
        
            <div className="pub_text">
                <div className={ author?.img ? "avatar" : "avatar no_ava"}>
                    {author?.img ? <img src={"data:photo/png;base64," + author.img} alt="" /> :  <img src="/img/donut.png" alt="" /> }
                </div>
                <div className="author_name">{author?.name}</div> 
                {pub?.text}
            </div>

            <div className="tool edit" type="publication"><img src="/img/edit.png" alt="edit" /></div>
            <div className="tool delete" type="publication"><img src="/img/delete.png" alt="delete" /></div>

        </div>
    )
}

function SwiperPublication(props) {
    const pub = props.publication;
    const author = props.author;
    const swiper_content = [];
    let edit_access = false;

    if (pub.img) {
        for (let i = 0; i < pub?.img?.length; i++) {
            const img = pub.img[i];
            swiper_content.push(<Img key={888 + i} src={img.url} />);
        }
    }

    if (props?.auth?.role == "admin") {
        edit_access = true;
    } else if (props?.auth.role == "dentist") {
        if (props?.auth?.author.guid == author.guid) {
            edit_access = true;
        }
    }
    

    return (
        <div className="pub" guid={pub.guid }>
            <div className="pub_title">{pub.title}</div>
            <div className="pub_photos swiper" type={pub.type}>
                <div className="swiper-wrapper">
                    {swiper_content}
                </div>
                <div className="next_slide"><img src="https://abclinic.uz/img/next.png" alt="prev" /></div>
                <div className="prev_slide"><img src="https://abclinic.uz/img/next.png" alt="next" /></div>
            </div>
            <div className="pub_text">  
                <div className={ author?.img ? "avatar" : "avatar no_ava"}>
                    {author?.img ? <img src={"data:photo/png;base64," + author.img} alt="" /> :  <img src="/img/donut.png" alt="" /> }
                </div>
                <div className="author_name">{author?.name}</div> 
                {pub?.text}
            </div>

            {
                edit_access ? 
                <div className="tool edit" type="publication"><img src="/img/edit.png" alt="edit" /></div>
                : ""
            }
            {
                edit_access ? 
                <div className="tool delete" type="publication"><img src="/img/delete.png" alt="delete" /></div>
                : ""
            }
            


            
            
        </div>
    )
}

function Category(props) {
    const swiperUpdt = props.func;
    const func = () => {
        ClickEvent(props.pack, props.global)
        swiperUpdt();
    }
    return (
        <NavLink to={`/blogs/publications/`+ props.category.guid} onClick={func} style={{left: `${13*props.id}%`, zIndex: `${props.count - props.id + 1}`}} guid={props.category.guid} data-count={props.id} className={"category " + props.category.path}>
            <div className="name">{props.category.title}</div>
            <div className="back"><img src={props.category.img} alt="category photos" /></div>
        </NavLink>
    )
}

function ClickEvent(pack, global) {
    setTimeout(()=>{
        const thisCategory = document.querySelector(".category.active");
        if (!thisCategory) {
            return;
        }
        const categories = document.querySelectorAll(".category");
        const catCount = Number(thisCategory.getAttribute("data-count"));
        for (let k = 0; k < categories.length; k++) {
            const categ = categories[k];
            const prevcount = Number(categ.getAttribute("data-count"));
            if (prevcount < catCount) { 
                categ.setAttribute("data-count", `${prevcount + 1}`);
            }
        }
        thisCategory.setAttribute("data-count", 0);
        CategoryLeft(pack, global)
    }, 100)
}

function CategoryLeft(pack, global) {
    const categoriesPack = pack;
    if (categoriesPack) {
        AddData("publications", global);
        Edit(categoriesPack, "publications", global.authors);
        Delete(categoriesPack, "publications");
    }
    const categories = document.querySelectorAll(".category");
    const categoryAdd = document.querySelector(".add[type=category]");

    if (!categoryAdd) {
        return
    }
    categoryAdd.style.cssText = `
            left: ${13 * categories.length}%;
            z-index: 1;
    `;
    for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        const count = Number(category.getAttribute("data-count"));
        const left = `${13 * count}%`;
        const zindex = categories.length - count + 1;
        category.style.cssText = `
            left: ${left};
            z-index: ${zindex};
        `;
    }
}

function Add(props) {
    return (
        <div className="add" style={props.type == "category" ? {left: `${props.count * 13}%`, zIndex: "1"} : {}} type={props.type} >{props.text}</div>
    )
}

function Toolbar(props) {
    return (
        <div className="toolbar">
            <div className="dots">
                <span></span><span></span><span></span>
            </div>
        </div>
    )
}
function Bar(props) {
    return (
        <div className="bar">
            <div type={props.type} className="tool edit">
                <div className="icon">
                    <img src="/img/edit.png" alt="view" />
                </div>
                <div className="desc">
                    edit
                </div>
            </div>
            <div type={props.type} className="tool delete">
                <div className="icon">
                    <img src="/img/delete.png" alt="view" />
                </div>
                <div className="desc">
                    delete
                </div>
            </div>
        </div>
    )
}

function ToolbarController() {
    const toolbar = document.querySelector(".selectbar .bar");
    const dots = document.querySelector(".selectbar .dots");
    if (!toolbar) {
        return;
    }
    dots.addEventListener("click", () => {
        const thisCategory = document.querySelector(".category.active");
        if (!thisCategory) {
            return;
        }
        if (toolbar.classList.contains("active")) {
            window.removeEventListener("click", () => {
                toolbar.classList.remove("active");
            },  { once: true });
        } else {
            setTimeout(() => {
                window.addEventListener("click", () => {
                    toolbar.classList.remove("active");
                },  { once: true })
            }, 50);
        }
        toolbar.classList.toggle("active");        
    })
}

function Blogs(props) {
    const categories = [];
    const categoriesPack = props.categories;
    const swiperRef = useRef(null);
    const publications = [];
    const data = props.publications;
    const {global, setGlobal} = useGlobal();
    const {auth} = useGlobal();
    const authors = auth.authors;


    function SwiperUpdate() {

        setTimeout(() => { 
            Swiper.use([Navigation]);
            swiperRef.current = new Swiper('.pub_photos[type="swiper"]', {

                navigation: {
                   nextEl: ".next_slide",
                   prevEl: ".prev_slide"
                },
            
            
                slidesPerView: 3,
                slideToClickedSlide: true,
                initialSlide: 1,  
                centeredSlides: true,
                spaceBetween: window.innerWidth * 0.0,
                slideActiveClass: "active_slide",
            });
            return () => {
                if (swiperRef.current) {
                  swiperRef.current.destroy();
                }
              };
        }, 200);
    }

    console.log(authors);

    for (let i = 0; i < authors?.length; i++) {
        const author = authors[i];
        for (let g = 0; g < data.length; g++) {
            const publication = data[g];
            
            if (author?.guid == publication?.author?.guid) {
                publication["author"] = author;
            }
        }
    }

    console.log(data);

    if (data) {
        for (let i = 0; i < data?.length; i++) {
            const publication = data[i];
            if (publication?.type == "swiper") {
                publications.push(<SwiperPublication publication={publication} auth={auth} author={publication?.author} />);
            } else if (publication?.type == "video") {
                publications.push(<VideoPublication publication={publication} auth={auth} author={publication?.author} />);
            }
        }
        
    }

    useEffect(()=>{

        const authors = auth.authors;

        if (authors || auth.role == "dentist") {
            auth.role == "dentist" ? 
                setGlobal({author: auth.author , role: "dentist"}) :
                setGlobal({authors: authors , role: "admin"});
   
                if (auth.role == "dentist") {
                    AddData("selectbar", {author: auth.author , role: "dentist"});
                    Edit(categoriesPack, "selectbar"); 
                    Delete(categoriesPack, "selectbar");  
                    CategoryLeft(categoriesPack, {author: auth.author , role: "dentist"});
                    setTimeout(() => {
                        ToolbarController();
                    }, 300)
                } else {
                    AddData("selectbar", {authors: authors , role: "admin"});
                    Edit(categoriesPack, "selectbar"); 
                    Delete(categoriesPack, "selectbar");  
                    CategoryLeft(categoriesPack, {authors: authors , role: "admin"});
                    setTimeout(() => {
                        ToolbarController();
                    }, 300)
                }   

        }
        

        
        
        SwiperUpdate();
    }, [auth])




    if(global) {
        if (categoriesPack) {
            if (categoriesPack.length) {  
                categories.push(<Toolbar />);
                categories.push(<Bar type="category" />);
            }
            for (let i = 0; i < categoriesPack.length; i++) {
                const category = categoriesPack[i].category;
                categories.push(<Category global={global} count={categoriesPack.length} func={SwiperUpdate} pack={categoriesPack} id={i} category={category} />);
            }
            categories.push(<Add type="category" count={categoriesPack.length} />);
            
        }
    }

    
    


    return (
        <div className="contentBack">
            <div className="title">Публикации</div>      
            <div className="selectbar">{categories}</div>
            <div className="publications">
                <Add type="publication" text="Post"/>
                {publications}
            </div>
        </div>
    )
}

export default Blogs;