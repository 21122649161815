import { createContext , useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({});
    const [global, setGlobal] = useState(0);

    return (
        <AuthContext.Provider value={{auth, setAuth, global, setGlobal}} >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;