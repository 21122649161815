import { NavLink, Route, Routes } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useGlobal from "./hooks/useAuthContext";
import LogOut from "./hooks/logout";
import CheckRights from "./hooks/checkRights";
import useAxiosPrivate from "./hooks/useAxiosPrivate";
import { AddData } from "./comands/add";
import { Delete } from "./comands/delete";
import { Edit } from "./comands/edit";
import { Js } from "./script";


function User(props) {
    return (
        <div className="user" role={props?.role} id={props.guid}>
            <div className={props?.img ? "user_ava" : "user_ava no_ava"}>
                {props?.role == "dentist" && props?.img ?
                    <img src={"data:photo/png;base64," + props?.img} alt="" />
                    :
                    <img src={"/img/donut.png"} alt="" />
                }
                {props?.role == "dentist" ?
                    <label htmlFor={"edit_" + props.guid} className="add_img">
                        <img src="/img/edit.png" alt="" />
                        <input type="file" id={"edit_" + props.guid} onChange={props?.func} style={{ opacity: 0, visibility: "hidden", display: "none" }} />
                    </label>
                    :
                    ""
                }
            </div>
            <div className="user_name">{props?.firstname + " " + props?.lastname}</div>
            <div className="user_role">{props.role}</div>
            <div className="toolbar">
                <div className="tool edit"><img src="/img/edit.png" alt="edit" /></div>
                <div className="tool delete"><img src="/img/delete.png" alt="delete" /></div>
            </div>
        </div>
    )
}

function Button(props) {
    return (
        <button onClick={props.users} id="users">Users</button>
    )
}

function Main(props) {
    const [count, setCount] = useState(0);
    const { auth } = useGlobal();
    const list = useRef(null);
    const page = useRef(null);
    const stick = useRef(null);
    const authors = auth.authors;
    const users = auth.users;
    const role = auth.role;
    console.log(role);
    const UsersPage = page.current;
    const usersElements = [];
    const axios = useAxiosPrivate();


    const ChangeAuthorImage = (e) => {
        var img = new Image();
        var objectUrl = URL.createObjectURL(e.target.files[0]);
        img.onload = function () {
            URL.revokeObjectURL(objectUrl);
            if (!(this.height == this.width) || e.target.files[0].size > 100 * 1024) {
                return alert("Image should be square and should not exceed the limit 100 kb");
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = (reader.result.split(","))[1];
                const author_id = e.target.parentElement.parentElement.parentElement.id;


                for (let i = 0; i < authors.length; i++) {
                    const author = authors[i];
                    if (author.guid == author_id) {
                        const fetch = axios({
                            url: "/v1/authors/" + author_id, method: "PUT", data: {
                                img: base64,
                                name: author.name
                            }
                        });
                        fetch.then(() => {
                            window.location.reload();
                        })
                    }
                }
            };
            reader.readAsDataURL(e.target.files[0]);
        };
        img.src = objectUrl;
    }


    for (let i = 0; i < users?.length; i++) {
        const user = users[i];
        if (user.role == "admin") {
            continue
        } else if (user.role == "dentist") {
            const guid = user.guid;
            for (let j = 0; j < authors?.length; j++) {
                const author = authors[j];
                if (author.guid == guid) {
                    usersElements.push(<User func={ChangeAuthorImage} guid={user.guid} role={user.role} img={author.img} firstname={user.firstname} lastname={user.lastname} />);
                    break;
                }
            }
            continue;
        }

        usersElements.push(<User role={user.role} guid={user.guid} firstname={user.firstname} lastname={user.lastname} />);
    }

    usersElements.push(<div className="add" type="user"></div>)


    const Users = () => {

        UsersPage.classList.add("actived")

    }
    const Close = () => {

        UsersPage.classList.remove("actived");

    }


    const listControl = () => {
        stick?.current?.classList.toggle("active")
        list?.current?.classList.toggle("active");
    }

    useEffect(() => {
        if (count === 0) {
            Js()
            setCount(2);
        }
        AddData("users_page", null);
        Delete(users, "users_page")
        Edit(users, "users_page", authors);
        axios({ url: "/v1/authors", method: "get" })
    }, [Users]);

    return (
        <div className="admin_back">
            <img src="img/server.png" style={{ display: 'none' }} alt="server-err" />
            <div className="admin_header">
                <div className="sideBurger">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="profile">
                    <div className="name">{auth.username ? auth.username : "abclinic"}</div>
                    <div className="ava">
                        <div className={auth.author?.img ? "avatar" : "avatar no_ava"} >
                            <img src={auth.role == "dentist" && auth.author?.img ? "data:photo/png;base64," + auth.author.img : "/img/donut.png"} alt="" />
                        </div>
                        <span onClick={listControl} ref={stick}></span>
                        <div className="list" ref={list}>
                            {auth.role == "admin" ?
                                <Button users={Users} /> :
                                ""
                            }
                            <button id="logout" onClick={LogOut}>Log out</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="admin_sidebar">
                    <NavLink to="/" className="admin_title">abclinic.uz</NavLink>
                    <nav>
                        <div className='side_button openbar priority-1' id='pages'><div className='button_text'>Pages</div> <span></span></div>
                            {
                                role == "admin" ?
                                    <NavLink to="/staff" className="side_button priority-2 displayed" buttonlink="pages" id='staff'>Staff</NavLink>
                                    : ""
                            }
                            {
                                role == "secretary" || role == "admin" ?
                                <NavLink to="/services" className="side_button priority-2 displayed" buttonlink="pages" id='services'>Services</NavLink> : ""
                            }
                            {
                                role == "secretary" || role == "admin" ?
                                <NavLink to="/info" className="side_button priority-2 displayed" buttonlink="pages" id='info'>Info</NavLink> : ""
                            }
                            {
                                role == "dentist" || role == "admin" ?
                                <NavLink to="/blogs" className="side_button priority-2 displayed" buttonlink="pages" id='blogs'>Blogs</NavLink> : ""
                            }
                    </nav>
                </div>
                
                <div className="admin_main">
                    <div className="admin_welcome">
                        <span></span>
                        <div className="selectorList">
                            {
                                role == "admin" ?
                                    <NavLink to="/staff" className="selector" item="staff">
                                        <div className="selector_img"><img src="/img/staff.png" alt="selector" /></div>
                                        <div className="delector_desc">Staff</div>
                                    </NavLink>
                                    : ""
                            }

                            {
                                role == "secretary" || role == "admin" ?
                                    <NavLink to="/services" className="selector" item="services">
                                        <div className="selector_img"><img src="/img/service.png" alt="selector" /></div>
                                        <div className="delector_desc">Services</div>
                                    </NavLink> : ""
                            }

                            {
                                role == "secretary" || role == "admin" ?
                                    <NavLink to="/info" className="selector" item="info">
                                        <div className="selector_img"><img src="/img/info.png" alt="selector" /></div>
                                        <div className="delector_desc">Info</div>
                                    </NavLink> : ""
                            }

                            {
                                role == "dentist" || role == "admin" ?
                                    <NavLink to="/blogs" className="selector" item="blogs">
                                        <div className="selector_img"><img src="/img/blog.png" alt="selector" /></div>
                                        <div className="delector_desc">Blogs</div>
                                    </NavLink> : ""
                            }


                        </div>

                    </div>
                    <div className="admin_page">
                        {props.page}
                    </div>
                </div>
            </div>

            <div className="users_page" ref={page}>
                <div className="shadow" onClick={Close}></div>
                <div className="users_content">
                    <div className="close" onClick={Close}></div>
                    <div className="title">Users</div>
                    <div className="users">
                        {usersElements}
                    </div>
                </div>
            </div>

            <div className='view_page'>
                <div className='shadow'></div>
                <div className='view_content'>
                    <div className="mark"><img src="/img/view.png" alt="view" /></div>
                    <div className="close"></div>
                    <div className="data"></div>
                </div>
            </div>
            <div className='edit_page'>
                <div className='shadow'></div>
                <div className='edit_content'>
                    <div className="mark"><img src="/img/edit.png" alt="edit" /></div>
                    <div className="close"></div>
                    <div className="buttons">
                        <div className="cancel">cancel</div>
                        <div className="send">send</div>
                    </div>
                    <div className="data"></div>
                </div>
            </div>
            <div className="delete_check">
                <div className='shadow'></div>
                <div className="delete_content">
                    <div className="close"></div>
                    <div className="mark"><img src="/img/delete.png" alt="delete" /></div>
                    <div className="warn">Are you sure to delete <span></span></div>
                    <div className="variants">
                        <div className="no" id='no'>Cancel</div>
                        <div className="yes" id='yes'>I'm sure</div>
                    </div>
                </div>
            </div>
            <div className='adding_page'>
                <div className='shadow'></div>
                <div className='adding_content'>
                    <div className="mark"></div>
                    <div className="close"></div>
                    <div className="buttons">
                        <div className="cancel">cancel</div>
                        <div className="send">send</div>
                    </div>
                    <div className="data">
                        <div className="warn">Enter the name of the <span className="type"></span></div>
                        <div className='inputes'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;