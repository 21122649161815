import { useLayoutEffect, useRef, useState } from "react";
import useAuth from "./useAuthContext";
import useRefreshToken from "./useRefreshToken";
import useAxiosPrivate from "./useAxiosPrivate";
import {Cookies}  from "react-cookie";
import { NavLink, Outlet , useNavigate } from "react-router-dom";

const CheckAccess = () => {
    const Cookie = new Cookies();
    const [access, setAccess] = useState(false);
    const { setAuth } = useAuth();
    const refresh = useRefreshToken();
    const axios = useAxiosPrivate();

    useLayoutEffect(() => {
        let accessToken = Cookie.get("access");
        if (accessToken) {
            setAuth({accessToken});
            async function check() {
                try {
                    const user = await axios({url: "/v1/rbac/user",
                        headers: {
                                "Authorization": "Bearer " + accessToken
                        }
                    })


                    accessToken = Cookie.get("access");
                    

                    if (user?.status === 200) {
                        setAccess(true);
                        let users = null;
                        let author = null;
                        let authors = null;
                        const username = user?.data?.Username;
                        const firstname= user?.data?.firstname;
                        const lastname= user?.data?.lastname;
                        const role = user?.data?.role;
                        const userid= user?.data?.guid;


                        if (role == "admin") {
                            const userslist = await axios({url: "/v1/rbac/users",
                                headers: {
                                    "Authorization": "Bearer " + accessToken
                                }
                            })
                            const authorslist = await axios({url: "/v1/authors",
                                headers: {
                                    "Authorization": "Bearer " + accessToken
                                }
                            })
                            users = userslist.data;
                            authors = authorslist.data;
                        } else if (role == "dentist") {
                            const authorslist = await axios({url: "/v1/authors",
                                headers: {
                                    "Authorization": "Bearer " + accessToken
                                }
                            })
                            const authorInfo = await axios({url: "/v1/authors/info",
                                headers: {
                                    "Authorization": "Bearer " + accessToken
                                }
                            });
                            author = authorInfo.data;
                            authors = authorslist.data;
                        }


                        setAuth({
                            firstname, 
                            lastname, 
                            role, 
                            username, 
                            userid, 
                            accessToken, 
                            users, 
                            authors, 
                            author
                        });
                        
                    }
                } catch (err) {
                    setAccess(false)
                    Cookie.remove("access");
                    const newAccessToken = await refresh();
                    if (newAccessToken) {
                        Cookie.set("access", newAccessToken);
                        setAccess(true);
                    }
                }
            }
                
            check()
        } else {
            setAccess(false)
            window.location = "/login";
        }
    }, []);


    return (
        access ?
            <Outlet />
            : 
            <div className="loginPage"></div>
        );   

}

export default CheckAccess;