import { useLayoutEffect, useEffect , useState, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { AddData } from "../comands/add";
import { Delete } from "../comands/delete";
import { Edit } from "../comands/edit";


function Img(props) {
    return (
        <div className="img"><img src={props.img} alt={props.img} /></div>
    )
}

function Text(props) {
    return (
        <div className={"articleText " + props?.cl}>{props.text}</div>
    )
}

function Add(props) {
    return (
        <div className="add" type={props.type} ></div>
    )
}

function Article (props) {
    const data = [];

    if (props.img == "") {
        data.push(<Text text={props.text} cl="full" />);
    } else if (props.side == "right") {
        data.push(<Text text={props.text} cl="" />);
        data.push(<Img img={props.img} />)
    } else {
        data.push(<Img img={props.img} />)
        data.push(<Text text={props.text} cl="" />);
    } 
    

    return (
        <div className="article" guid={props.guid} >
            {data}

            <div className="tool edit"><img src="/img/edit.png" alt="edit" /></div>
            <div className="tool delete"><img src="/img/delete.png" alt="delete" /></div>
        </div>
    )
}

function Chapter(props) {
    const articles = [];
    const [articlesData, setData] = useState(0);
    const axios = useAxiosPrivate();
    const all = props.all;


    useLayoutEffect(() => {
        if (!articlesData) {
            async function fetchArticle() {
                const {data} = await axios({ url : "/v1/articles/" + props.guid } );
                return data;
            }

            fetchArticle().then((res) => {
                Edit(all, "information");
                Delete(all, "information");
                setData(res)
            },[])
        }
    }, []);
    if (articlesData) {
        for (let i = 0; i < articlesData.length; i++) {
            const article = articlesData[i];
            articles.push(<Article text={String(article.text)} guid={article.guid} img={article.img} side={article.side} />);
        }
    }    

    return (
        <div className="chapter" guid={props.guid} >
            <div className="chapter_title">{props.title}</div>
            <div className="articles">
                {articles}
                <Add type="article" />
            </div>
            <div className="tool edit"><img src="/img/edit.png" alt="edit" /></div>
            <div className="tool delete"><img src="/img/delete.png" alt="delete" /></div>
        </div>
    )
}

function Table(props) {
    if (props.chapters == 'networkerr') {
        return (
            <div className="nodata">
                <div className="errimg"><img src="/img/server.png" alt="server-err" /></div>
                <div className="errmassege">Не удаётся получить информацию с сервера</div>
            </div>
            )
    }

    const chapters = [];
    
    if (props.chapters) {
        for (let i = 0; i < props.chapters.length; i++) {
            const chapter = props.chapters[i];
            chapters.push(<Chapter all={props.all} title={chapter.name} guid={chapter.guid} />);
        }
    }    

    return (
        <div className="information">
            {chapters}
            <Add type="chapter" />
        </div>
    )
}

function Info(props) {
    const [chapters, setState] = useState(0);
    const [all, setAll] = useState(0);
    const axios = useAxiosPrivate();
    const table = [];


    useLayoutEffect(() => {

        if (!chapters) {
            async function fetchChapters() {
                try {
                    const {data} = await axios({ url : "/v1/articles/chapter"});
                    return data;
                } catch (error) {
                    console.log(error);
                    return "networkerr";
                }
                
            }
            fetchChapters().then((res) => {
                setState(res);
                async function fetchAll(props) {
                    try {
                        const all = [];
                        for (let i = 0; i < res.length; i++) {
                            const element = res[i];
                            const {data} = await axios({ url: "/v1/articles/" + element.guid });
    
                            element["articles"] = data;
    
                            all.push(element);
                        }
                        return all;
                    } catch (error) {
                        console.log(error);
                        return "err";
                    }
                    
                }

                fetchAll().then((all)=>{
                    setAll(all)
                    Edit(all, "information");
                    Delete(all, "information")
                    AddData("information")
                }, [])
            })
        }
    }, []);

    if (all) {
        table.push(<Table all={all} chapters={chapters}  />);
    }

    return (
        <div className="contentBack">
            <div className="title">Информация</div>       
            {table}
        </div>
    )
}

export default Info;