export function View(information, tablename){
    
    
    const chek = setInterval(() => {
        if (document.querySelector(`.${tablename}`)) {
            Lets();
            clearInterval(chek);
        }
    }, 100);

    function Lets() {
        const table = document.querySelector(`.${tablename}`);
        const viewPage = document.querySelector(".view_page");
        const buttons = table.querySelectorAll(".tool.view");

        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            button.addEventListener("click", () => {
                const id = button.parentElement.parentElement.querySelector(".column.id span").innerHTML;
                let dataObj = {};
                for (let i = 0; i < information.length; i++) {
                    const obj = information[i];
                    if (Number(obj.id) === Number(id)) {
                        dataObj = obj;
                    }
                }
                viewPage.lastElementChild.lastElementChild.innerHTML = "";
                
                for (const key in dataObj) {
                    const value = dataObj[key];
                    viewPage.lastElementChild.lastElementChild.insertAdjacentHTML(
                        "beforeend",
                        `
                            <div class='section'>
                                <div class="key">${key}</div>
                                <div class="value"></div>
                            </div>
                            
                        `
                    )
                    viewPage.lastElementChild.lastElementChild.lastElementChild.querySelector(".value").insertAdjacentText(
                        'beforeend',
                        value
                    )
                }
                
                viewPage.classList.add("actived");
            });
        }


    }    
    
}