import { useState, useEffect, useRef } from "react";
import axios from "../api/api";
import  {Cookies}  from "react-cookie";
import Swiper, {Navigation} from "swiper";

const Login = () => {
    const [username, setUser] = useState('');
    const [password, setPwd] = useState('');
    const Cookie = new Cookies();
    const userInput = useRef(null);
    const pwd = useRef(null);

    const submit = async (e) => {
        e.preventDefault();
        try {
            document.body.classList.add("loading");
            const {data} = await axios.post("/v1/login", JSON.stringify({username, password}), {
                headers: {
                    'Content-Type': "application/json"
                }
            });
            Cookie.set("refresh", data?.refresh_token);
            Cookie.set("access", data?.access_token);
            window.location = "/";
        } catch (err) {
            document.body.classList.remove("loading");
            userInput.current?.parentElement?.classList.add("wrong");
            pwd.current?.parentElement?.classList.add("wrong");
        }
    }

    const offing = (ex) => {
        const slides = document.querySelectorAll(".swiper-slide");
        for (let i = 0; i < slides.length; i++) {
            const slide = slides[i];
            if (slide == ex) {
                continue;
            }
            slide.classList.add("off");
        }
    }

    const activating = () => {
        offing();
        classification();

        const interval = setInterval(() => {
            const prev = document.querySelector("[slide-index='1']");
            const slides = document.querySelectorAll(".swiper-slide");

            prev.classList.remove("active");

            for (let i = 0; i < slides.length; i++) {
                const slide = slides[i];
                const index = Number(slide.getAttribute("slide-index"));
                if (index == 1) {
                    slide.classList.add("prev");
                    setTimeout(() => {
                        slide.classList.remove("prev");
                        slide.classList.add("off");
                    }, 2000);
                    slide.setAttribute("slide-index", 3);
                    continue
                }
                slide.setAttribute("slide-index", index - 1);
            }
            offing(prev);
            classification(); 
        }, 3500);

        return () => {
            if (interval) {
                clearInterval(interval) 
            }
        }
    }

    const classification = () => {
        const first = document.querySelector("[slide-index='1']");
        first?.classList.remove("off");
        first?.classList.add("active"); 
    }
   
    useEffect(() => {
        activating();
    } , [])

    return (
        <div className="loginPage">
            <div className="login">
                <div className="formContainer">
                    <form className="loginForm" onSubmit={(e) => {
                        submit(e);
                    }}>
                        <div className="title">Login</div>
                        <div className="subtitle">Sign in to your account</div>
                        <div className="input user">
                            <div className="sign">
                                <img src="/img/user.png" alt="username" />
                            </div>
                            <input
                                type="text" 
                                name="user"
                                onChange={(e) => {
                                    setUser(e.target.value);
                                }}
                                ref={userInput}
                                placeholder="Username"
                                className="inputArea"
                            />
                        </div>
                        <div className="input password">
                            <div className="sign">
                                <img src="/img/pwd.png" alt="password" />
                            </div>
                            <input
                                type="password" 
                                name="password"
                                onChange={(e) => {
                                    setPwd(e.target.value);
                                }}
                                ref={pwd}
                                placeholder="Password" 
                                className="inputArea"
                            />
                        </div>
                        <div className="buttonContainer">
                            <button>Login</button>
                        </div>
                    </form>
                </div>
                <div className="imageContainer">
                    <div className="loginImage">
                        <img src="/img/login-background.jpg" alt="backgound" />
                    </div>
                    <div className="login-swiper">
                            <div className="l_role swiper-slide active" slide-index="1">
                                <div className="l_image">
                                    <img src="/img/administrator.png" alt="admin" />
                                </div>
                                <div className="rolename">Administrator</div>
                            </div>
                            <div className="l_role swiper-slide" slide-index="2">
                                <div className="l_image">
                                    <img src="/img/secretary.png" alt="admin" />
                                </div>
                                <div className="rolename">Secretary</div>
                            </div>
                            <div className="l_role swiper-slide" slide-index="3">
                                <div className="l_image">
                                    <img src="/img/dentist.png" alt="admin" />
                                </div>
                                <div className="rolename">Dentist</div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;