import axios from "../api/api";
import useGlobal from "./useAuthContext";
import { Cookies } from "react-cookie";

const useRefreshToken = () => {
    const Cookie = new Cookies();
    const {auth, setAuth} = useGlobal();

    async function refresh() {
        const refreshToken = Cookie.get("refresh");
        if (!refreshToken) {
            window.location = "/login"
            return;
        }
        try {
            const response = await axios.post("/v1/refresh", JSON.stringify({refresh_token: refreshToken}));


            if (response?.status === 200) {
                const prevAuth = auth;
                prevAuth["accessToken"] = response?.data?.access_token;
                Cookie.set("refresh", response?.data?.refresh_token);
                Cookie.set("access", response?.data?.access_token);
                setAuth(prevAuth);
                return response?.data?.access_token;
            }
        } catch (error) {
            window.location = "/login";
        }        
    }

    return refresh;
}

export default useRefreshToken;