import axios from "../api/api";
import Swiper, {Navigation} from "swiper";
import { Edit } from "./edit";
import { Delete } from "./delete";
import { View } from "./view";
import { AddData } from "./add";

export function ContentUpdate(tablename){
    let swiperRef;
    
    switch (tablename) {
        case "publications":
            const category = document.querySelector(".category.active");
            if (category) {
                window.location.href = window.location.origin + "/blogs/publications/" + category.getAttribute("guid");
            } else {
                window.location.href = window.location.origin + "/blogs";
            }
                    
            break
        case "selectbar":
                window.location.href = window.location.origin + "/blogs";      
            break
        case "pricelist":
            async function fetchData() {
                try {
                    const get = (await axios.get("/v1/services/groups")).data;
                    return get;
                } catch (error) {
                    console.warn(error.message);
                }            
            }
            fetchData().then((response) => {
                async function fetchAllData() {
                    try {
                        const alldata = [];
                        for (let i = 0; i < response.length; i++) {
                            const id = response[i].guid;
                            const section = [];
                            const servicesData = (await axios.get("/v1/services/" + id)).data;
                            for (let k = 0; k < servicesData.length; k++) {
                                const element = servicesData[k];
                                section.push(element);
                            }
                            alldata.push({
                                guid: id,
                                name: response[i].name,
                                data: section
                            });
                        }
                        return alldata;
                    } catch (error) {
                        console.warn(error);
                        return 0;
                    }
                }
        
                fetchAllData().then((services) => {
                    document.querySelector(`.${tablename}`).removeAttribute("status");
                    PriceList(services);
                    Edit(services, tablename);
                    Delete(services, tablename);
                    AddData("pricelist");
                })
            })
            break;
    
        case "staff":
            async function fetchStuffData(props) {
                try {
                    const {data} = await axios.get("/v1/dentists");

                    for (let i = 0; i < data.length; i++) {
                        const dentist = data[i];
                        delete dentist["clone_name"];
                        delete dentist["side"];
                        delete dentist["priority"]
                    }

                    return data; 
                } catch (error) {
                    console.warn(error.message);
                }            
            }
            fetchStuffData().then((response) => {
                document.querySelector(`.${tablename}`).removeAttribute("status");
                Table({dentists: response});
                View(response, "staff");
                Edit(response, "staff");
            })
            
            break;
        case "information":
            async function fetchChapters() {
                const {data} = await axios.get("/v1/articles/chapter");
                return data;
            }
            fetchChapters().then((res) => {
                async function fetchAll(props) {
                    const all = [];
                    for (let i = 0; i < res.length; i++) {
                        const element = res[i];
                        const {data} = await axios.get( "/v1/articles/" + element.guid);

                        element["articles"] = data;

                        all.push(element);
                    }
                    return all;
                }

                fetchAll().then((all)=>{
                    document.querySelector(`.${tablename}`).removeAttribute("status");
                    InfoTable(all) 
                    Edit(all, "information");
                    Delete(all, "information")
                    AddData("information");
                }, [])
            })
            
            break;
    }


    function SwiperUpdate() {
        setTimeout(() => {
            Swiper.use([Navigation]);
            swiperRef = new Swiper('.pub_photos[type="swiper"]', {

                navigation: {
                   nextEl: ".next_slide",
                   prevEl: ".prev_slide"
                },
            
            
                slidesPerView: 2,
                slideToClickedSlide: true,
                initialSlide: 1,  
                centeredSlides: true,
                spaceBetween: window.innerWidth * 0.04,
                slideActiveClass: "active_slide",
            });
            return () => {
                if (swiperRef) {
                  swiperRef.destroy();
                }
              };
        }, 200);
    }

    function Img(props) {
        return `
            <img src="${props.src}" class="pub_photo swiper-slide" alt="job" />
        `
    }
    function Publications(data) {
        let publications = "";
        for (let i = 0; i < data.length; i++) {
            const publication = data[i];
            publications = publications + Publication({publication: publication});
        }
        document.querySelector(".publications").innerHTML = publications;
    }

    function Publication(props) {
        const pub = props.publication;
        
        let swiper_content = "";

        for (let i = 0; i < pub.img.length; i++) {
            const img = pub.img[i];
            swiper_content = swiper_content + Img({src: img.url});
        }


        return `
            <div class="pub" guid="${pub.guid}">
                <div class="tool edit" type="publication" ><img src="/img/edit.png" alt="edit" /></div>
                <div class="tool delete" type="publication" ><img src="/img/delete.png" alt="delete" /></div>
                <div class="pub_title">${pub.title}</div>
                <div class="pub_photos swiper" type={pub.type}>
                    <div class="swiper-wrapper">
                        ${swiper_content}
                    </div>
                    <div class="next_slide"><img src="https://abclinic.uz/img/next.png" alt="prev" /></div>
                    <div class="prev_slide"><img src="https://abclinic.uz/img/next.png" alt="next" /></div>
                </div>
                <div class="pub_text">${pub.text}</div>
            </div>
        `
    }

    function InfoTable(props) {
        let chapters = "";

        if (props) {
            for (let i = 0; i  < props.length; i++) {
                const chapter = props[i];
                chapters = chapters + Chapter({
                    name: chapter.name,
                    guid: chapter.guid,
                    articles: chapter.articles
                });
            }
        }

        document.querySelector(`.${tablename}`).innerHTML = `
        
            ${chapters}
            ${Add({type: "chapter"})}
        
        `;
    }

    function Chapter(props) {
        let articles = "";


        if (props.articles) {
            for (let i = 0; i < props.articles.length; i++) {
                const article = props.articles[i];
                articles = articles + Article({
                    img: article.img,
                    guid: article.guid,
                    text: article.text,
                    side: article.side
                });
            }
        }

        return `
        <div class="chapter" guid="${props.guid}" >
            <div class="chapter_title">${props.name}</div>
            <div class="articles">
                ${articles}
                ${Add({type: "article"})}
            </div>
            <div class="tool edit"><img src="/img/edit.png" alt="edit" /></div>
            <div class="tool delete"><img src="/img/delete.png" alt="delete" /></div>
        </div>
        `
    }

    function Article(props) {
        let data = "";

        if (props.img == "") {
            data = data + `<div class="articleText full">${props.text}</div>`;
        } else if (props.side == "right") {
            data = data + `<div class="articleText">${props.text}</div>`;
            data = data + `<div class="img"><img src="${props.img}" alt="${props.img}" /></div>`;
        } else {
            data = data + `<div class="img"><img src="${props.img}" alt="${props.img}" /></div>`;
            data = data + `<div class="articleText">${props.text}</div>`;
        }

        return `
        <div class="article" guid="${props.guid}" >
            ${data}
            <div class="tool edit"><img src="/img/edit.png" alt="edit" /></div>
            <div class="tool delete"><img src="/img/delete.png" alt="delete" /></div>
        </div>
        `
    }

    function Table(props) {
        var columnLength = Object.keys(props.dentists[0]).length;
        var rowLength = props.dentists.length;
        let rows = Bar({
            number: 0,
            dentists: props.dentists[0]
        });


        for (let i = 0; i < props.dentists.length; i++) {
            const dentist = props.dentists[i];
            rows = rows + Row({
                number: i+1,
                dentists: dentist
            });
        }

        document.querySelector(`.${tablename}`).innerHTML = `
            ${rows}
        `;  
    }

    function Bar(props) {
        let columns = '';
    
        for (const im in props.dentists) {
                const el = props.dentists[im];
                columns = columns + Column( {
                    text: im,
                    class: im
                } );
        }

        return `
            <div class=${"row bar _" + props.number } type="dentist">
                ${columns}
            </div>
        `
    }
    function ToolBar(props) {
        return `
            <div class="column toolbar">
                <div class="tool view"><img src="/img/view.png" alt="view" /></div>
                <div class="tool edit"><img src="/img/edit.png" alt="edit" /></div>
            </div>
        `
    }

    function Row(props) {
        let columns = '';
    
        for (const im in props.dentists) {
                const el = props.dentists[im];
                columns = columns + Column( {
                    text: el,
                    class: im
                } );
        }

        columns = columns + ToolBar();

        return `
            <div class=${"row _" + props.number } type="dentist">
                ${columns}
            </div>
        `
    }
        
    function Column(props) {
        let text = "";
        if (props.class === "info") {
            for (let i = 0; i <= 40; i++) {
                const letter = props.text[i];
                if (!letter) {
                    break;
                }
                text = text + letter;
                if (i === 40) {
                    text = text + "...";
                }
            }
        } else {
            text = props.text;
        }

        return `
            <div class="${"column " + props.class}"><span>${text}</span></div>
        `
    }

    
    function PriceList(data) {
        const pricelist = document.querySelector(".pricelist");
        let groups = "";

        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            const group = Group({
                guid: element.guid,
                title: element.name,
                lines: element.data
            })
            groups = groups + group;
        }
    
    
        pricelist.innerHTML = `
            ${groups}
            ${Add({type: "group"})}
        `;
    }

    function Group(props) {
        let lines = "";
        for (let i = 0; i < props.lines.length; i++) {
            const element = props.lines[i];
            const line = Line({
                guid: element.guid,
                name: element.name,
                price: element.price,
                urgent_price: element.urgent_price
            })
            lines = lines + line;
        }

        return `
        <div class="group" guid=${props.guid} type="group">
            <div class="group_title">
                <div class="tool edit"><img src="/img/edit.png" alt="edit" /></div>
                <div class="group_name">${props.title}</div>
                <div class="tool delete"><img src="/img/delete.png" alt="delete" /></div>
            </div>
            <div class="services">
                ${lines}
                ${Add({type: "service"})}
            </div>
        </div>
        `
        
    }

    function Add(props) {
        return `
            <div class="add" type=${props.type}></div>
        `
    }

    function Line(props) {
        return `
            <div class="service" guid=${props.guid} >
            <div class="service_name">${props.name}</div>
            <div class="part" type="service">
                <div class="price">${props.price}</div>
                <div class="price urgent">${props.urgent_price}</div>
                <div class="toolbar">
                    <div class="tool edit"><img src="/img/edit.png" alt="edit" /></div>
                    <div class="tool delete"><img src="/img/delete.png" alt="delete" /></div>
                </div>
                </div>
            </div>
        `
    }

    return 0;

}