import { useLayoutEffect , useRef, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { View } from "../comands/view";
import { Edit } from "../comands/edit";

function Column(props) {
    let text = "";
    if (props.class === "info") {
        for (let i = 0; i <= 70; i++) {
            const letter = props.text[i];
            if (!letter) {
                break
            }
            text = text + letter;
            if (i === 70) {
                text = text + "...";
            }
        }
    } else {
        text = props.text;
    }
    
    return (
        <div className={"column " + props.class}><span>{text}</span></div>
    )
}
function ToolBar(props) {
    return (
        <div className="column toolbar">
            <div className="tool view"><img src="/img/view.png" alt="view" /></div>
            <div className="tool edit"><img src="/img/edit.png" alt="edit" /></div>
        </div>
    )
}

function Row(props) {

    const columns = [];

    
    
    for (const im in props.dentists) {
            const el = props.dentists[im];
            let x = Math.floor((Math.random() * 1000) + 1);
            columns.push(<Column key={x} class={im} text={el} />);            
    }

    columns.push(<ToolBar key="5d" />);

    return (
        <div className={"row _" + props.number } type="dentist">
            {columns}
        </div>
    )
}

function Bar(props) {
    const columns = [];

    for (const im in props.dentists) {
        let x = Math.floor((Math.random() * 1000) + 1);
        columns.push(<Column key={x} class={im} text={im} />);            
    }

    return (
        <div className={"row bar _" + props.number }>
            {columns}
        </div>
    )
}


function Table(props) {
    if (props.dentists) {
        var columnLength = Object.keys(props.dentists[0]).length;
        var rowLength = props.dentists.length;
        const rows = [];
        let xy = Math.floor((Math.random() * 1100) + 1);
        

        rows.push(<Bar key={xy} number="0" dentists={props.dentists[0]} />);
        
        const order = [4,3,5,1,2,6,7];


        for (let i = 0; i < props.dentists.length; i++) {
            const el = props.dentists[i];
            
            for (let i = 0; i < order.length; i++) {
                const index = order[i];

                if (Number(el.id) == index) {
                    order[i] = el;
                    break
                }
            }
        }
        for (let i = 0; i < order.length; i++) {
            const el = order[i];
            let x = Math.floor((Math.random() * 1000) + 1);
            rows.push(<Row key={x} number={i+1} dentists={el} />);
        }

        return (
            <div className="table staff" ref={props.table} columns={columnLength} rows={rowLength}>
                {rows}
            </div>
        )
    }
    if (props?.message == "err_network") {
        return (
        <div className="nodata">
             <div className="errimg"><img src="/img/server.png" alt="server-err" /></div>
             <div className="errmessage">Не удаётся получить информацию с сервера</div>
        </div>
        ) 
    }
}

function Staff(props) {
    const staffTable = [];
    const [data, setData] = useState(0);
    const [message, setMessage] = useState("");
    const table = useRef(null);
    const axios = useAxiosPrivate();

    useLayoutEffect(() => {

        async function fetchData(props) {
            try {
                const {data} = await axios({ url : "/v1/dentists" , method : "get"});

                for (let i = 0; i < data.length; i++) {
                    const dentist = data[i];
                    delete dentist["clone_name"];
                    delete dentist["side"];
                    delete dentist["priority"]
                }
                return data;
            } catch (error) {
                console.warn(error.message);
                setMessage("err_network");
            }            
        }
        fetchData().then((response) => {
            setData(response);
            const wait = setInterval(() => {
                if (table.current) {
                    View(response, "staff");
                    Edit(response, "staff");
                    clearInterval(wait);
                }
            }, 100)
        })
    }, [table.current]);

    if (data) {
        staffTable.push(<Table key="" table={table} message={message} dentists={data} />);
    }


    
    
    return (
        <div className="contentBack">
            <div className="title">Команда</div>       
            {staffTable}
        </div>
    )
    
}

export default Staff;