import axios from "../api/api";
import { ContentUpdate } from "./update";

export function Edit(information, tablename, authors) {


    const chek = setInterval(() => {
        try {
            if (document.querySelector(`.${tablename}`)) {
                Lets();
                clearInterval(chek);
            }
        } catch (error) {
            console.log(error);
            clearInterval(chek);
        }
    }, 100);

    function Lets() {
        const table = document.querySelector(`.${tablename}`);
        const editPage = document.querySelector(".edit_page");
        const buttons = table.querySelectorAll(".tool.edit");

        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            button.onclick = () => {


                if (!information) {
                    return alert("Error, no information");
                }


                let id, parent;
                const infoObj = {};
                let type = button.parentElement.parentElement.getAttribute("type");
                if (type == "dentist") {
                    id = button.parentElement.parentElement.querySelector(".column.id span").innerHTML;
                } else if (button.getAttribute("type") == "publication") {
                    parent = button.parentElement;
                    if (!parent) {
                        alert("Select a publication to edit");
                        return;
                    }
                    id = parent.getAttribute("guid");
                    type = "publication";
                } else if (button.getAttribute("type") == "category") {
                    parent = document.querySelector(".category.active");
                    if (!parent) {
                        alert("Select a category to edit");
                        return;
                    }
                    id = parent.getAttribute("guid");
                    type = "category";
                } else if (button.parentElement.classList.contains("article")) {
                    parent = button.parentElement;
                    id = parent.getAttribute("guid");
                    type = "article";
                } else if (button.parentElement.classList.contains("chapter")) {
                    parent = button.parentElement;
                    id = parent.getAttribute("guid");
                    type = "chapter";
                } else if (button.parentElement.parentElement.classList.contains("user") ) {
                    parent = button.parentElement.parentElement;
                    id = parent.getAttribute("id");
                    type = "user";
                } else if (button.parentElement.classList.contains("toolbar")) {
                    parent = button.parentElement.parentElement.parentElement;
                    id = parent.getAttribute("guid");
                } else {
                    parent = button.parentElement.parentElement;
                    id = button.parentElement.parentElement.getAttribute("guid")
                }
                let dataObj;

                console.log(id);

                console.log(information);

                switch (type) {
                    case "publication":
                        for (let i = 0; i < information.length; i++) {
                            const obj = information[i];
                            const category = obj.category
                            for (let k = 0; k < obj.publications.length; k++) {
                                const publication = obj.publications[k];
                                if (publication.guid == id) {
                                    infoObj["categoryId"] = category.guid
                                    dataObj = publication;
                                }
                            }
                        }
                        break;
                    case "category":
                        for (let i = 0; i < information.length; i++) {
                            const category = information[i].category;
                            if (category.guid == id) {
                                dataObj = category;
                            }
                        }
                        break
                    case "dentist":
                        for (let i = 0; i < information.length; i++) {
                            const obj = information[i];
                            if (obj.id == id) {
                                dataObj = obj;
                            }
                        }
                        break;
                    case "service":

                        const preParentId = parent.parentElement.parentElement.getAttribute("guid");
                        for (let k = 0; k < information.length; k++) {
                            const element = information[k];
                            if (element.guid == preParentId) {
                                for (let i = 0; i < element.data.length; i++) {
                                    const obj = element.data[i];

                                    if (obj.guid == id) {
                                        dataObj = obj;
                                    }
                                }
                            }
                        }
                        break;
                    case "group":
                        for (let i = 0; i < information.length; i++) {
                            const element = information[i];
                            if (element.guid == id) {
                                const childList = element.data;
                                const title = element.name;
                                infoObj["title"] = title;
                                dataObj = childList;
                            }
                        }
                        break;
                    case "chapter":
                        for (let i = 0; i < information.length; i++) {
                            const element = information[i];
                            if (element.guid == id) {
                                dataObj = {
                                    name: element.name,
                                    guid: element.guid
                                };
                            }
                        }
                        break;
                    case "article":
                    
                        for (let i = 0; i < information.length; i++) {
                            const element = information[i];
                            if (element.guid == button.parentElement.parentElement.parentElement.getAttribute("guid")) {
                                for (let k = 0; k < element.articles.length; k++) {
                                    const article = element.articles[k];
                                    if (article.guid == id) {
                                        dataObj = article;
                                    }
                                }
                            }
                        }
                        break;
                    case "user":
                        for (let i = 0; i < information.length; i++) {
                            const element = information[i];
                            if (element.guid == id) {
                                dataObj = element;
                            }
                        }
                        break
                    default:
                        return "error";
                }

                console.log(dataObj);

                if (dataObj) {
                    editPage.lastElementChild.lastElementChild.innerHTML = "";
                    editPage.setAttribute("type", type);

                    function ImageArticle() {
                        const img = document.querySelector("#adding_img");
                        img.addEventListener("change", (event) => {
                            const [file] = event.target.files;
                            if (!file) {
                                return;
                            }
                            const label = document.querySelector(".edit_page .img_file");
                            infoObj["image"] = file;
                            const url = URL.createObjectURL(file);
                            if (label.querySelector(".photo")) {
                                label.querySelector(".image").src = url;
                                return;
                            }
                            label.classList.remove("empty");
                            label.insertAdjacentHTML(
                                "afterbegin",
                                `
                                    <div class="photo">
                                        <img src="${url}" alt="prevPhoto" class="image" />
                                    </div>
                                    <div class="shadow"><img src="/img/edit.png" alt="edit" /></div>
                                `
                            )
                        })
                    }

                    function editImage(count) {
                        const img = document.querySelector("#changing_img");
                        infoObj["images"] = [];
                        img.addEventListener("change", (event) => {
                            const [file] = event.target.files;
                            const images = document.querySelector(".edit_page #media .images");
                            const url = URL.createObjectURL(file);
                            infoObj["images"].push(file);
                            images.lastElementChild.insertAdjacentHTML(
                                `beforebegin`,
                                `
                                <div class="photo _${count.count}"> 
                                    <img src="${url}" class="image" alt="prevPhoto" />
                                    <div class="shadow delete_img"><img src="/img/delete.png" alt="delete" /></div>
                                </div>
                                `
                            )
                            DeleteImg(document.querySelector(".photo._" + count.count), count.count - 1);
                            count.count++
                        })
                    }
                    function Vid() {
                        const video = document.querySelector("#changing_video");
                        video.addEventListener("change", (event) => {
                            if (!event.target.files) {
                                return;
                            }
                            infoObj["video"] = event.target.files[0];

                            document.querySelector(".edit_page #media .video").classList.remove("plus");
                            if (document.querySelector(".edit_page #media .video .name")) {
                                document.querySelector(".edit_page #media .video .name").innerHTML = event.target.files[0].name;
                                return
                            }
                            document.querySelector(".edit_page #media .video").insertAdjacentHTML("afterbegin", `<div class="name">${event.target.files[0].name}</div>`)
                        })
                    }
                    function DeleteImg(element, index) {
                        const shadow = element.querySelector(".delete_img");
                        if (!shadow) {
                            return;
                        }
                        if (element.classList.contains("def")) {
                            shadow.addEventListener("click", () => {
                                infoObj["delImages"].push(index);
                                element.remove();
                            });
                            return;
                        }
                        shadow.addEventListener("click", () => {
                            element.remove();
                            infoObj["images"][index] = null;
                        });
                    }

                    function ImageCategory() {
                        const img = document.querySelector("#adding_img");
                        img.addEventListener("change", (event) => {
                            const [file] = event.target.files;
                            if (!file) {
                                return
                            }
                            const label = document.querySelector(".edit_page .img_file");
                            infoObj["image"] = file;
                            const url = URL.createObjectURL(file);
                            label.querySelector(".image").src = url;
                        })
                    }

                    switch (type) {
                        case "user":
                            editPage.setAttribute("type", "article");
                            editPage.lastElementChild.lastElementChild.innerHTML = `
                            <div class="section">
                                <label for="adding_firstname">Firstname:</label>
                                <input type="text" id="adding_firstname" name="title" class="value" value="${dataObj.firstname}" />
                            </div>
                            <div class="section">
                                <label for="adding_lastname">Lastname:</label>
                                <input type="text" id="adding_lastname" name="title" class="value" value="${dataObj.lastname}" />
                            </div>
                            <div class="section">
                                <label for="adding_username">Username:</label>
                                <input type="text" id="adding_username" name="title" class="value" value="${dataObj.Username}" />
                            </div>
                            <div class="section">
                                <label for="adding_firstname">Password:</label>
                                <input type="password" id="adding_password" name="title" class="value" value="" />
                            </div>
                            `;
                            break
                        case "article":
                            editPage.lastElementChild.lastElementChild.innerHTML = `
                            <div class='section'>
                                <div class="key">text:</div>  
                                <textarea type="text" name="text" class="value long" id="adding_text" rows="max">${dataObj.text}</textarea>
                            </div>
                            <div class="section">
                                <div class="key">img:</div>
                                <label class="img_file" for="adding_img">
                                    <input type="file" accept="image/*,.jpg,.png,.webp" id="adding_img" name="img" />
                                </label>
                            </div>
                            <div class="section sel">
                                <label for="adding_side" class="key">side:</label>
                                <select name="side" id="adding_side">
                                    <option value="left">left</option>
                                    <option value="right">right</option>
                                </select>
                            </div>
                            `;
                            document.querySelector(`#adding_side option[value="${dataObj.side}"]`)?.setAttribute("selected", "");
                            if (dataObj.img) {
                                document.querySelector(".img_file").insertAdjacentHTML(
                                    "afterbegin",
                                    `
                                        <div class="photo">
                                            <img src="${dataObj.img}" alt="prevPhoto" class="image" />
                                        </div>
                                        <div class="shadow"><img src="/img/edit.png" alt="edit" /></div>
                                    `
                                )
                            } else {
                                document.querySelector(".img_file").classList.add("empty")
                            }
                            ImageArticle()
                            
                            break
                        case "publication":
                            editPage.lastElementChild.lastElementChild.innerHTML = `
                            <div class="section">
                                <label for="adding_title">title:</label>
                                <input type="text" id="adding_title" maxlength="100" name="title" class="value" value="${dataObj.title}" />
                            </div>
                            <div class="section media" type="swiper" id="media">
                                
                            </div>
                            <div class="section">
                                <label for="adding_text">text:</label>
                                <textarea type="text" id="adding_text" class="value long" name="text" >${dataObj.text}</textarea>
                            </div>
                            `
                            if (authors) {
                                for (let i = 0; i < authors.length; i++) {
                                    const author = authors[i];
                                    if (author.guid == dataObj.author.guid) {
                                        document.querySelector(".section").insertAdjacentHTML(
                                            "beforebegin",
                                            `
                                            <div class="section">
                                                <div class="adding_author fixed_author" id="${author.guid}">
                                                    <div class="${author?.img ? "user_ava" : `${"user_ava no_ava"}`}">
                                                        ${author?.img ? 
                                                            `<img src=${"data:photo/png;base64," + author?.img} alt=""/>`
                                                            : 
                                                            `<img src=${"/img/donut.png"} alt=""/>`
                                                        }               
                                                    </div>
                                                    ${author.name} 
                                                </div>
                                            </div>
                                            `
                                        )
                                    }
                                }
                            }
                            const count = { count: 1, index: 1 };
                            if (dataObj.type == "swiper") {
                                document.querySelector(".edit_page #media").setAttribute("type", "swiper");
                                document.querySelector(".edit_page #media").innerHTML = `
                                    <label for="adding_img">images:</label>
                                    <div id="adding_images" class="images">
                                    <label for="changing_img">
                                        <input type="file" id="changing_img" class="image" accept="image/*,.jpg,.png,.web" name="img" />
                                    </label>
                                    </div>
                                `
                                const images = document.querySelector(".edit_page #media .images");
                                for (let i = dataObj.img.length - 1; i >= 0; i--) {
                                    const img = dataObj.img[i];
                                    images.insertAdjacentHTML(
                                        `afterbegin`,
                                        `
                                        <div class="photo i_${count.index} def"> 
                                            <img src="${img.url}" class="image" alt="prevPhoto" />
                                            <div class="shadow delete_img"><img src="/img/delete.png" alt="delete" /></div>
                                        </div>
                                        `
                                    )
                                    DeleteImg(document.querySelector(".photo.i_" + count.index), i);
                                    count.index++;
                                }
                                infoObj["delImages"] = [];
                                editImage(count);
                            } else if (dataObj.type == "video") {
                                document.querySelector(".edit_page #media").setAttribute("type", "video");
                                document.querySelector(".edit_page #media").innerHTML = `
                                    <label>video:</label>
                                    <label for="changing_video" class="video">
                                        <input type="file" id="changing_video" accept="video/*,.mp4,.mkv" name="img" />
                                    </label>
                                `;
                                document.querySelector(".edit_page #media .video").insertAdjacentHTML("afterbegin", `<div class="name">${dataObj.video}</div>`);
                                Vid();
                            }
                            
                            break;
                        case "category":
                            editPage.lastElementChild.lastElementChild.innerHTML = `
                            <div class='section'>
                                <div class="key">title</div>  
                                <textarea type="text" name="title" class="value" id="adding_title" rows="max">${dataObj.title}</textarea>
                            </div>
                            <div class="section">
                                <div class="key">img</div>
                                <label class="img_file" for="adding_img" class="name">
                                    <div class="photo">
                                        <img src="${dataObj.img}" alt="prevPhoto" class="image" />
                                    </div>
                                    <div class="shadow"><img src="/img/edit.png" alt="edit" /></div>
                                    <input type="file" accept="image/*,.jpg,.png,.webp" id="adding_img" name="img" />
                                </label>
                            </div>
                            <div class="section">
                                <label for="adding_description" class="key">description:</label>
                                <textarea id="adding_description" name="description" class="value long">${dataObj.description}</textarea>
                            </div>
                            `;
                            ImageCategory()
                            
                            break
                        case "group":
                            const title = infoObj.title;
                            editPage.lastElementChild.lastElementChild.innerHTML =
                                `
                                <div class="title"><input type="text" name="name" class="value" value="${title}" /></div>
                                <div class="edit_list"></div>
                            `;
                            for (let i = 0; i < dataObj.length; i++) {
                                const element = dataObj[i];

                                editPage.lastElementChild.lastElementChild.lastElementChild.insertAdjacentHTML(
                                    "beforeend",
                                    `
                                        <div class='section edit_service' guid="${element["guid"]}" >

                                        </div>
                                        
                                    `
                                )

                                for (const key in element) {
                                    let inputType = "text";
                                    if (key === "id" || key === "guid") {
                                        continue;
                                    }
                                    const value = element[key];
                                    let cl = "";
                                    if (value.length > 100) {
                                        cl = "long"
                                    }
                                    if (key == "price" || key == "urgent_price") {
                                        inputType = "number";
                                    }


                                    editPage.lastElementChild.lastElementChild.lastElementChild.lastElementChild.insertAdjacentHTML(
                                        "beforeend",
                                        `
                                            <input type="${inputType}" name="${key}" def="${value}" value="${value}" class="value ${key + " " + cl}" />
                                            
                                        `
                                    )
                                }
                            }
                            break;
                        default:
                            if (dataObj["guid"]) {
                                editPage.lastElementChild.lastElementChild.setAttribute("guid", dataObj["guid"]);
                            } else {
                                editPage.lastElementChild.lastElementChild.setAttribute("guid", dataObj["id"]);
                            }
                            for (const key in dataObj) {
                                if (key === "id" || key === "guid") {
                                    continue;
                                }
                                const value = dataObj[key];
                                let cl = "";
                                let inputType = "text";
                                if (value.length > 100) {
                                    cl = "long"
                                }
                                if (key == "price" || key == "urgent_price") {
                                    inputType = "number";
                                }

                                editPage.lastElementChild.lastElementChild.insertAdjacentHTML(
                                    "beforeend",
                                    `
                                        <div class='section'>
                                            <div class="key">${key}</div>  
                                            <textarea type=${inputType} name="${key}" class="value ${cl}" rows="max">${value}</textarea>
                                        </div>
                                        
                                    `
                                )

                            }
                            break;
                    }

                    editPage.classList.add("actived");

                    const send = document.querySelector(".edit_content .send");
                    const cancel = document.querySelector(".edit_content .cancel");

                    cancel.onclick = () => {
                        const edit_shadow = document.querySelector(".edit_page .shadow");
                        for (const key in infoObj) {
                            if (Object.hasOwnProperty.call(infoObj, key)) {
                                infoObj[key] = undefined;
                            }
                        }
                        edit_shadow.parentElement.classList.remove("actived");
                    }



                    send.onclick = async () => {
                        const sections = document.querySelectorAll(".section");
                        const info = [];

                        async function switchcase() {
                            async function changeAuthor(name, author) {
                                try {
                                    const res = axios({
                                        url: "/v1/authors/" + dataObj.guid,
                                        method: "PUT",
                                        data: {
                                            name: name,
                                            img: author.img
                                        }
                                    })
                                } catch (err) {
                                    
                                }
                            }

                            

                            async function uploadingImg(image) {
                                try {
                                    const formdata = new FormData();
                                    formdata.append("file", image);
                                    const { data } = await axios({
                                        method: "post",
                                        url: "/v1/file",
                                        headers: {
                                            'Content-Type': 'multipart/form-data',
                                        },
                                        data: formdata
                                    });
                                    return data;
                                } catch (error) {
                                    return "error";
                                }
                            }
                            async function deletingImg(url) {
                                try {
                                    if (!url) {
                                        return;
                                    }
                                    await axios({
                                        method: 'delete',
                                        url: "v1/file",
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        data: JSON.stringify({ url: url })
                                    });
                                } catch (err) {
                                    return "error";
                                }
                            }

                            async function uploadingImage(image) {
                                try {
                                    const formdata = new FormData();
                                    formdata.append("file", image);
                                    const { data } = await axios({
                                        method: "post",
                                        url: "/v1/file",
                                        headers: {
                                            'Content-Type': 'multipart/form-data',
                                        },
                                        data: formdata
                                    });
                                    return data;
                                } catch (error) {
                                    return "error";
                                }
                            }
                            async function deletingImage(url) {
                                try {
                                    if (!url) {
                                        return;
                                    }
                                    await axios({
                                        method: 'delete',
                                        url: "v1/file",
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        data: JSON.stringify({ url: url })
                                    });
                                } catch (err) {
                                    return "error";
                                }
                            }

                            async function fetchVideo(video) {
                                const formData = new FormData();
                                formData.append("file", video);
                                try {
                                    const {data} = await axios({
                                        method: 'POST',
                                        url:  "/v1/file",                                
                                        headers: {
                                          'Content-Type': 'multipart/form-data',
                                        },
                                        data: formData,
                                    });
                                    return data;

                                } catch (err) {
                                    return "";
                                }
                            }

                            async function UploadImages(images) {
                                try {
                                    const result = [];

                                    for (let i = 0; i < images.length; i++) {
                                        const img = images[i];
                                        if (!img) {
                                            continue
                                        }
                                        const formData = new FormData();
                                        formData.append("file", img);
                                        const { data } = await axios({
                                            method: 'POST',
                                            url: "/v1/file",
                                            headers: {
                                                'Content-Type': 'multipart/form-data',
                                            },
                                            data: formData,
                                        });
                                        result.push({ url: data.url });
                                    }

                                    return result;

                                } catch (err) {
                                    console.log(err);
                                    return "";
                                }
                            }

                            async function DeleteVideo(url) {
                                try {
                                    await axios({
                                        method: 'delete',
                                        url: "/v1/file",
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        data: JSON.stringify({ url: url })
                                    });
                                } catch (error) {
                                    console.log(error);
                                }
                            }

                            async function DeleteImages(urls) {
                                try {
                                    for (let i = 0; i < urls.length; i++) {
                                        const url = urls[i];
                                        if (!url) {
                                            return;
                                        }
                                        await axios({
                                            method: 'delete',
                                            url: "/v1/file",
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            data: JSON.stringify({ url: url })
                                        });
                                    }

                                } catch (err) {
                                    return "error";
                                }
                            }

                            switch (type) {
                                case "user":
                                    let author;

                                    document.body.classList.add("loading");

                                    for (let i = 0; i < authors?.length; i++) {
                                        const element = authors[i];
                                        if (element.guid == dataObj.guid) {
                                            author = element;
                                        }
                                    }

                                    await changeAuthor(document.querySelector("#adding_username").value, author).then(()=>{
                                        info.push({
                                            url: "/v1/rbac/user/" + dataObj.guid,
                                            data: {
                                                firstname: document.getElementById("adding_firstname").value,
                                                lastname: document.getElementById("adding_lastname").value,
                                                username: document.getElementById("adding_username").value,
                                                password: document.getElementById("adding_password").value,
                                                role: dataObj.role
                                            } 
                                        })
                                    });
                                    
                                    break
                                case "article":
                                    const img = infoObj["image"];
                                    const areaValues = document.querySelectorAll(".value");
                                    const select = document.querySelector(".section select");
                                    let comparing = 0;
                                    if (!(select.value == dataObj.side)) {
                                        comparing++
                                    }
                                    for (let i = 0; i < areaValues.length; i++) {
                                        const value = areaValues[i];
                                        if (!(value.value == value.textContent)) {
                                            comparing = 1;
                                        }
                                    }
                                    if ((!img) && (!comparing)) {
                                        return alert("At first edit the article");
                                    }

                                    document.body.classList.add("loading");


                                    if (!img) {
                                        info.push({
                                            url: `/v1/articles/` + dataObj.guid,
                                            data: {
                                                text: document.getElementById("adding_text").value,
                                                side: document.getElementById("adding_side").value,
                                                img: dataObj.img
                                            }
                                        });
                                        document.body.classList.remove("loading");
                                        break;
                                    }

                                    await uploadingImg(img).then((url) => {
                                        if (!url || url == "error") {
                                            document.body.classList.remove("loading");
                                            return alert("Error. Cannot upload the image");
                                        }
                                        info.push({
                                            url: `/v1/articles/` + dataObj.guid,
                                            data: {
                                                text: document.getElementById("adding_text").value,
                                                side: document.getElementById("adding_side").value,
                                                img: url.url
                                            }
                                        })
                                        document.body.classList.remove("loading");
        
                                        if (dataObj.img) {
                                            deletingImg(dataObj.img);
                                        }
                                    })

                                    
                                    break
                                case "category":
                                    const editedImage = infoObj["image"];
                                    const values = document.querySelectorAll(".value");
                                    let difference = 0;
                                    for (let i = 0; i < values.length; i++) {
                                        const value = values[i];
                                        if (!(value.value == value.textContent)) {
                                            difference = 1;
                                        }
                                    }
                                    if ((!editedImage) && (!difference)) {
                                        return alert("At first edit the category");
                                    }

                                    document.body.classList.add("loading");


                                    if (!editedImage) {
                                        info.push({
                                            url: `/v1/blogs/` + id,
                                            data: {
                                                title: document.getElementById("adding_title").value,
                                                description: document.getElementById("adding_description").value,
                                                img: dataObj.img
                                            }
                                        });
                                        document.body.classList.remove("loading");
                                        break;
                                    }

                                    await uploadingImage(editedImage).then((url) => {
                                        if (!url || url == "error") {
                                            document.body.classList.remove("loading");
                                            return alert("Error. Cannot upload the image");
                                        }
                                        info.push({
                                            url: `/v1/blogs/` + id,
                                            data: {
                                                title: document.getElementById("adding_title").value,
                                                description: document.getElementById("adding_description").value,
                                                img: url.url
                                            }
                                        })
                                        document.body.classList.remove("loading");
                                        deletingImage(dataObj.img);
                                    })

                                    
                                    break
                                case "publication":

                                    if (dataObj.type == "swiper") {
                                        const deletedIndexs = infoObj.delImages;
                                        const prevImages = [];
                                        const deletedImages = [];
                                        const addedImages = infoObj.images;
                                        const urls = [];

                                        for (let i = 0; i < dataObj.img.length; i++) {
                                            const element = dataObj.img[i];
                                            prevImages.push(element)
                                        }

                                        for (let i = 0; i < addedImages.length; i++) {

                                            if (!addedImages[i]) {
                                                addedImages.splice(i, 1)
                                            }
                                        }

                                        if ((!addedImages.length && !deletedIndexs.length) && (document.getElementById("adding_text").value == dataObj.text) && (document.getElementById("adding_title").value == dataObj.title)) {
                                            return alert("At first edit the publication");
                                        }

                                        document.body.classList.add("loading");


                                        for (let i = 0; i < deletedIndexs.length; i++) {
                                            const index = deletedIndexs[i];
                                            if (!prevImages[index]) {
                                                break
                                            }
                                            deletedImages.push(prevImages[index].url);
                                            prevImages.splice(index, 1);
                                        }


                                        for (let i = 0; 1; i++) {
                                            if (!prevImages[i]) {
                                                break;
                                            }
                                            const img = prevImages[i].url;
                                            if (img) {
                                                urls.push({ url: img })
                                            }
                                        }


                                        if (!addedImages.length) {
                                            let err = false;
                                            await DeleteImages(deletedImages).then((result) => {
                                                if (result == "error") {
                                                    document.body.classList.remove("loading");
                                                    return alert("Error, cannot delete the images");
                                                }
                                                info.push({
                                                    url: `/v1/blogs/publication/` + id,
                                                    data: {
                                                        title: document.getElementById("adding_title").value,
                                                        type: dataObj.type,
                                                        text: document.getElementById("adding_text").value,
                                                        img: urls
                                                    }
                                                })
                                                document.body.classList.remove("loading");

                                            })
                                            break;
                                        }
                                        
                                        await UploadImages(addedImages).then((data) => {
                                            if (data == "") {
                                                document.body.classList.remove("loading");
                                                return alert("Cannot upload the images");
                                            }
                                            for (let i = 0; 1; i++) {
                                                if (!data[i]) {
                                                    break
                                                }
                                                const imgA = data[i].url;
                                                if (imgA) {
                                                    urls.push({ url: imgA });
                                                }
                                            };
                                            info.push({
                                                url: `/v1/blogs/publication/` + id,
                                                data: {
                                                    title: document.getElementById("adding_title").value,
                                                    type: dataObj.type,
                                                    text: document.getElementById("adding_text").value,
                                                    img: urls,
                                                    video: ""
                                                }
                                            })
                                            document.body.classList.remove("loading");
                                            DeleteImages(deletedImages).then(() => {});
                                        })
                                    } else if (dataObj.type == "video") {
                                        const video = infoObj["video"];
                                        if (!video) {
                                            info.push({
                                                url: `/v1/blogs/publication/` + id,
                                                data: {
                                                    title: document.getElementById("adding_title").value,
                                                    type: dataObj.type,
                                                    text: document.getElementById("adding_text").value,
                                                    img: null,
                                                    video: dataObj.video
                                                }
                                            }) 
                                            break;
                                        }
                                        document.body.classList.add("loading");


                                        await fetchVideo(infoObj.video).then((data)=>{
    
                                            document.body.classList.remove("loading");
                                            if (data == "") {
                                                return alert("Error. Cannot upload an video");
                                            }
                                            info.push({
                                                url:  `/v1/blogs/publication/` + id,
                                                data: {
                                                    title: document.getElementById("adding_title").value,
                                                    type: "video",
                                                    img: [],
                                                    text: document.getElementById("adding_text").value,
                                                    video: data.url
                                                }
                                            })
    
                                            DeleteVideo(dataObj.video);
                                        })
                                    }
                                    

                                    break
                                case "group":
                                    for (let i = 0; i < sections.length; i++) {
                                        const section = sections[i];
                                        const sectionData = {};
                                        let compare = 0;
                                        infoObj["url"] = "/v1/services/groups/" + editPage.lastElementChild.lastElementChild.getAttribute("guid");
                                        const values = section.querySelectorAll(".value");
                                        for (let i = 0; i < values.length; i++) {
                                            const input = values[i];
                                            if (input.value == input.getAttribute("def")) {
                                                ++compare;
                                            }
                                            if (input.name == "price" || input.name == "urgent_price") {
                                                sectionData[input.name] = Number(input.value);
                                                continue;
                                            }
                                            sectionData[input.name] = input.value;
                                        }
                                        if (compare < values.length) {
                                            info.push({
                                                url: "/v1/services/" + section.getAttribute("guid"),
                                                data: sectionData
                                            });
                                        }
                                    }
                                    info.push({
                                        url: "/v1/services/groups/" + id,
                                        data: {
                                            name: editPage.querySelector(".title input").value
                                        }
                                    })
                                    break;
                                default:

                                    const sectionData = {};
                                    let compare = 0;
                                    for (let i = 0; i < sections.length; i++) {
                                        const sectionVal = sections[i].querySelector(".value");
                                        if (!sectionVal) {
                                            continue;
                                        }
                                        if (sectionVal.value == sectionVal.textContent || sectionVal.value == sectionVal.def) {
                                            compare++;
                                        }
                                        if (sectionVal.name == "price" || sectionVal.name == "urgent_price") {
                                            sectionData[sectionVal.name] = Number(sectionVal.value);
                                            continue;
                                        }
                                        sectionData[sectionVal.name] = sectionVal.value;
                                    }
                                    if (compare < sections.length) {
                                        if (type == "service") {
                                            info.push({
                                                url: "/v1/services/" + editPage.lastElementChild.lastElementChild.getAttribute("guid"),
                                                data: sectionData
                                            });
                                        } else if (type == "dentist") {
                                            info.push({
                                                url: "/v1/dentists/" + editPage.lastElementChild.lastElementChild.getAttribute("guid"),
                                                data: sectionData
                                            });
                                        } else if (type == "chapter") {
                                            info.push({
                                                url: "/v1/articles/chapter/" + editPage.lastElementChild.lastElementChild.getAttribute("guid"),
                                                data: sectionData
                                            });
                                        } else {
                                            return alert("error");
                                        }
                                    } else {
                                        return alert("First edit the areas")
                                    }
                                    break;
                            }
                        }

                        switchcase().then(() => {

                            if (!info.length) {
                                return
                            }

                            Post();
                        })




                        function Post() {
                            async function fetchData() {
                                try {
                                    for (let i = 0; i < info.length; i++) {
                                        const senddata = info[i].data;
                                        const { data } = await axios({
                                            method: 'PUT',
                                            url: info[i].url,
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            data: senddata
                                        });
                                    };
                                } catch (error) {
                                    return "error";
                                }
                            }

                            fetchData().then((res) => {
                                if (res == "error") {
                                    return alert("Network error");
                                }
                                if (type == "user") {
                                    window.location.reload();
                                }
                                if (type = ! "category") {
                                    document.querySelector(`.${tablename}`).innerHTML = `
                                        <img src="/img/loading.gif" alt="loading">
                                    `;
                                    document.querySelector(`.${tablename}`).setAttribute("status", "loading")
                                }
                                ContentUpdate(tablename);
                            })


                        }


                        const edit_shadow = document.querySelector(".edit_page .shadow");

                        edit_shadow.parentElement.classList.remove("actived");
                    }

                }



            }


        }



    }

}