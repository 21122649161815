import { useLayoutEffect , useState } from "react";
import { Edit } from "../comands/edit";
import { Delete } from "../comands/delete";
import { AddData } from "../comands/add";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function Line(props) {
    return(
        <div className="service" guid={props.guid} >
            <div className="service_name">{props.name}</div>
            <div className="part" type="service">
                <div className="price">{props.price}</div>
                <div className="price urgent">{props.urgent}</div>
                <div className="toolbar">
                    <div className="tool edit"><img src="/img/edit.png" alt="edit" /></div>
                    <div className="tool delete"><img src="/img/delete.png" alt="delete" /></div>
                </div>
            </div>
        </div>
    )
}

function Add(props) {
    return (
        <div className="add" type={props.type}></div>
    )
}

function Group(props) {
    const lines = [];
    const [list, listSet] = useState(0);
    const [state, setState] = useState(0);
    const [guid] = useState(props.guid);
    const axios = useAxiosPrivate();



    useLayoutEffect(() => {
        if (state < 1) {
            async function fetchData() {
                try {
                    const get = (await axios( { url : `/v1/services/${guid}`})).data;
                    return get;
                } catch (error) {
                    console.warn(error.message);
                }            
            }
            fetchData().then((response) => {
                listSet(response)
                setState(2);
            })
        }
                
    })

    if (list) {
        for (let i = 0; i < list.length; i++) {
            const line = list[i];
            lines.push(<Line guid={line.guid} name={line.name} price={line.price} urgent={line.urgent_price} />);
        }
    }

    return(
        <div className="group" guid={guid} type="group">
            <div className="group_title">
                <div className="tool edit"><img src="/img/edit.png" alt="edit" /></div>
                <div className="group_name">{props.title}</div>
                <div className="tool delete"><img src="/img/delete.png" alt="delete" /></div>
            </div>
            <div className="services">
                {lines}
                <Add type="service" />
            </div>
        </div>
    )
}


function PriceList(props) {
    if (props.groups == 'networkerr') {
        return (
            <div className="nodata">
                <div className="errimg"><img src="/img/server.png" alt="server-err" /></div>
                <div className="errmassege">Не удаётся получить информацию с сервера</div>
            </div>
            )
    }
    const DOMEN = props.domen;
    const groups = [];

    for (let i = 0; i < props.groups.length; i++) {
        const group = props.groups[i];
        groups.push(<Group domen={DOMEN} title={group.name} guid={group.guid} />);
    }

    return(
        <div className="pricelist">
            {groups}
            <Add type="group" />
        </div>
    )
}

function Services(props) {
    const pricelist = [];
    const DOMEN = props.domen;   
    const [data, setData] = useState(0);
    const axios = useAxiosPrivate();

    useLayoutEffect(() => {
        async function fetchData(props) {
            try {
                const get = (await axios({url : "/v1/services/groups"})).data;
                return get;
            } catch (error) {
                console.warn(error.message);
                return "networkerr";
            }            
        }
        fetchData().then((response) => {
            setData(response);
            async function fetchAllData(props) {
                try {
                    const alldata = [];
                    for (let i = 0; i < response.length; i++) {
                        const id = response[i].guid;
                        const section = [];
                        const servicesData = (await axios({url: "/v1/services/" + id})).data;
                        for (let k = 0; k < servicesData.length; k++) {
                            const element = servicesData[k];
                            section.push(element);
                        } 
                        alldata.push({
                            guid: id,
                            name: response[i].name,
                            data: section
                        });
                    }
                    return alldata;
                } catch (error) {
                    console.warn(error);
                    return 0;
                }
            }
   
            fetchAllData().then((services) => {
                const wait = setInterval(()=>{
                    if (document.querySelector(".add[type=group]")) {
                        Edit(services, "pricelist", DOMEN);
                        Delete(services, "pricelist", DOMEN);
                        AddData("pricelist", DOMEN);
                        clearInterval(wait);
                    }
                }, 100);

            })
        })
    }, [])


    if (data) {
        pricelist.push(<PriceList domen={DOMEN} groups={data} />);
    }

    return (
        <div className="contentBack">
            <div className="title">Прайс лист</div>       
            {pricelist}
        </div>
    )
}

export default Services;