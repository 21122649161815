import axios from "../api/api";
import useRefreshToken from './useRefreshToken';
import useAuth from "./useAuthContext";

function useAxiosPrivate() {
    const { auth } = useAuth();
    const refresh = useRefreshToken();

    const axiosPrivate = ({ ...options }) => {
    
        axios.defaults.headers.common.Authorization = `Bearer ${auth?.accessToken}`;
        
        const onSuccess = (response) => response;
        const onError = async (error) => {
                
            const prevRequest = error?.config;
           
            prevRequest.sent = true; 
            const newAccessToken = await refresh();
            prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return axios(prevRequest);
            
            return error;
    
        };
    
        return axios(options).then(onSuccess).catch(onError);
    
    }

    return axiosPrivate;
}

export default useAxiosPrivate;
