import { Routes, Route} from "react-router-dom";
import axios from "./api/api"
import Main from './Main';
import Staff from './views/Staff';
import Services from './views/Services';
import Info from './views/Info';
import Blogs from './views/Blogs';
import CheckAccess from './hooks/checkAccess';
import Login from './views/Login';
import useAuth from "./hooks/useAuthContext";
import { useEffect, useState } from "react";

function App(props) {
    const [data, setData] = useState([]);
    const {auth, setAuth} = useAuth()
    const DOMEN = props.domen;;
    const blogRoutes = [];

    useEffect(()=>{
      async function fetchData() {
        try {
            const {data} = await axios.get("/v1/blogs");
            return data;
        } catch (error) {
            console.log(error);    
        }
      }
      fetchData().then((res) => {
        async function fetchAllData() {
            try {
                const result = [];
                for (let i = 0; i < res?.length; i++) {
                    const category = res[i];
                    const {data} = await axios.get("/v1/blogs/"+ category.guid +"/publication");
                    result.push({
                        category: category,
                        publications: data
                    })
                }
                
                return {result};
            } catch (error) {
                console.log(error);    
            }
        }
        fetchAllData().then((response) => {
            setData(response.result);
        })
    })
    }, [])
    
    for (let i = 0; i < data?.length; i++) {
        const category = data[i].category;
        const publications = data[i].publications;

        blogRoutes.push(<Route path={"/blogs/publications/"+category.guid} element={<Main page={<Blogs domen={DOMEN} categories={data} publications={publications} />} />} /> )
    }


  return (
    <Routes>
      
      <Route element={<CheckAccess />}>
        <Route path='' element={<Main page={""} />} />
        <Route path='staff' element={<Main page={<Staff domen={DOMEN} />} />} />
        <Route path='services' element={<Main page={<Services domen={DOMEN} />} />}/>
        <Route path='info' element={<Main page={<Info domen={DOMEN} />} /> } />
        <Route path='blogs' element={<Main page={<Blogs domen={DOMEN} categories={data} publications={<div className='publications'></div>} />} />} />
        {blogRoutes}
      </Route>  
      
         

      <Route path='/login' element={<Login />} />


    </Routes>
  );
}

export default App;
