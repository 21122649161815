import axios from "../api/api";
import { ContentUpdate } from "./update";

export function Delete(information, tablename, domen){
    
    
    const chek = setInterval(() => {
        if (document.querySelector(`.${tablename}`)) {
            Lets();
            clearInterval(chek);
        }
    }, 100);

    function Lets() {
        const table = document.querySelector(`.${tablename}`);
        const deletePage = document.querySelector(".delete_check");
        const buttons = table.querySelectorAll(".tool.delete");
        const DOMEN = domen;

        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            button.onclick = () => {
                let id, parent;
                const infoObj = {};
                let type = button.parentElement.parentElement.getAttribute("type"); 
                if (type == "dentist") {
                    id = button.parentElement.parentElement.querySelector(".column.id span").innerHTML;
                } else if (button.getAttribute("type") == "publication") {
                    parent = button.parentElement;
                    if (!parent) {
                        alert("Select a publication to edit");
                        return;
                    }
                    id = parent.getAttribute("guid");
                    type = "publication";
                } else if (button.getAttribute("type") == "category") {
                    parent = document.querySelector(".category.active");
                    if (!parent) {
                        alert("Select a category to edit");
                        return;
                    }
                    id = parent.getAttribute("guid");
                    type = "category";
                } else if (button.parentElement.classList.contains("article") ) {
                    parent = button.parentElement;
                    id = parent.getAttribute("guid");
                    type = "article";
                } else if (button.parentElement.classList.contains("chapter") ) {
                    parent = button.parentElement;
                    id = parent.getAttribute("guid");
                    type = "chapter";
                } else if (button.parentElement.parentElement.classList.contains("user") ) {
                    parent = button.parentElement.parentElement;
                    id = parent.getAttribute("id");
                    type = "user";
                } else if (button.parentElement.classList.contains("toolbar")) {
                    parent = button.parentElement.parentElement.parentElement; 
                    id = parent.getAttribute("guid");
                } else {
                    parent = button.parentElement.parentElement;
                    id = parent.getAttribute("guid")
                }


                let dataObj;
                
                if (type === "service") {
                    const preParentId = parent.parentElement.parentElement.getAttribute("guid");
                    for (let k = 0; k < information.length; k++) {
                        const element = information[k];
                        if (element.guid == preParentId) {
                            for (let i = 0; i < element.data.length; i++) {
                                const obj = element.data[i];
                                if (obj.guid == id) {
                                    dataObj = {
                                        guid: obj.guid,
                                        title: `<span class="type">${type}</span> "${obj.name}"`,
                                    };
                                }
                            }
                        }
                    }
                } else if (type === "publication") {
                    const activeCategory = document.querySelector(".category.active");
                    if (!activeCategory) {
                        return alert("Select a category");
                    }
                    for (let k = 0; k < information.length; k++) {
                        const category = information[k].category;
                        const publications = information[k].publications
                        if (category.guid = activeCategory.getAttribute("guid")) {
                            for (let i = 0; i < publications.length; i++) {
                                const obj = publications[i];
                                if (obj.guid == id) {
                                    dataObj = {
                                        guid: obj.guid,
                                        title: `<span class="type">${type}</span> "${obj.title}"`,
                                    };
                                }
                            }
                        }                        
                    }
                } else if (type === "category") {
                    const activeCategory = document.querySelector(".category.active");
                    if (!activeCategory) {
                        return alert("Select a category");
                    }
                    for (let k = 0; k < information.length; k++) {
                        const category = information[k].category;
                        const publications = information[k].publications;
                        if (category.guid == activeCategory.getAttribute("guid")) {
                            dataObj = {
                                guid: category.guid,
                                url: category.img,
                                title: `<span class="type">${type}</span> "${category.title}" ${publications.length} el.`
                            };
                            break;
                        }                        
                    }
                } else if (type === "group") {
                    for (let i = 0; i < information.length; i++) {
                        const element = information[i];
                        if (element.guid == id) {
                            let childList = 0;
                            if (element.data) {
                                childList = element.data.length;
                            }
                            const title = element.name;
                            dataObj = {
                                guid: id,
                                title: `<span class="type">${type} "${title}"</span> ${childList} el.`,
                            };
                        }
                    }
                    
                } else if (type === "article") {
                    const preParentId = parent.parentElement.parentElement.getAttribute("guid");
                    for (let k = 0; k < information.length; k++) {
                        const element = information[k];
                        if (element.guid == preParentId) {
                            for (let i = 0; i < element.articles.length; i++) {
                                const obj = element.articles[i];
                                if (obj.guid == id) {
                                    dataObj = {
                                        guid: obj.guid,
                                        url: obj.img,
                                        title: `<span class="type">${type}</span> 1 element`,
                                    }
                                }
                            }
                        }
                    }
                }else if (type === "chapter") {
                    for (let i = 0; i < information.length; i++) {
                        const element = information[i];
                        if (element.guid == id) {
                            let childList = 0;
                            if (element.articles) {
                                childList = element.articles.length;
                            }
                            const title = element.name;
                            dataObj = {
                                guid: id,
                                title: `<span class="type">${type} "${title}"</span> ${childList} el.`,
                            };
                        }
                    }
                    
                }else if (type === "user") {
                    for (let i = 0; i < information.length; i++) {
                        const element = information[i];
                        if (element.guid == id) {
                            const name = element.Username;
                            const role = element.role;
                            dataObj = {
                                guid: id,
                                role: role,
                                title: `<span class="type">${role} "${name}"</span>`,
                            };
                        }
                    }
                    
                }

                deletePage.classList.add("actived");

                if (dataObj) {
                    const warnSpan = deletePage.querySelector(".warn span");
                    warnSpan.innerHTML = dataObj.title;
                    
                

                    const yes = document.querySelector(".delete_content .yes");
                    const no = document.querySelector(".delete_content .no");

                    no.onclick = () => {

                        const delete_shadow = document.querySelector(".delete_check .shadow");

                        delete_shadow.parentElement.classList.remove("actived");

                    }
                
                    yes.onclick = async () => {
                        let info;

                        async function deleteAuthor(id){
                            try {
                                const req = await axios({url: "/v1/authors/" + id, method: "DELETE"});

                                return req.status == 200 ? "success" : "error";
                            } catch (error) {
                                return "error";
                            }
                        }
                        async function deleteImage(url) {
                            try {
                                await axios({
                                    method: "delete",
                                    url: "/v1/file",
                                    data: JSON.stringify({url: url})}); 
                                return 200;                                    
                            } catch (err) {
                                return "err";
                            }
                        }
                        async function deleteImg(url) {
                            try {
                                await axios({
                                    method: "delete",
                                    url: "/v1/file",
                                    data: JSON.stringify({url: url})}); 
                                return 200;                                    
                            } catch (err) {
                                return "err";
                            }
                        }

                        switch (type) {
                            case "user":
                                info = {
                                    url:   `/v1/rbac/user/${dataObj.guid}`,
                                }
                                document.body.classList.add("loading");


                                if (dataObj.role == "dentist") {
                                    await deleteAuthor(dataObj.guid).then((status) => {
                                        document.body.classList.remove("loading");

                                        if (status == "error") {
                                            return alert("Cannot delete author");
                                        }
                                
                                        info = {
                                            url:   `/v1/rbac/user/${dataObj.guid}`,
                                        }
                                    })
                                } else {
                                    info = {
                                        url:   `/v1/rbac/user/${dataObj.guid}`,
                                    }
                                }


                                
                                break;
                            case "group":
                                info = {
                                    url:   `/v1/services/groups/${dataObj.guid}`,
                                }
                                break;
                            case "chapter": 
                                
                                info = {
                                    url:   `/v1/articles/chapter/${dataObj.guid}`,
                                }
                               
                                break
                            case "article": 

                                if (!dataObj.url) {
                                    info = {
                                        url:  `/v1/articles/${dataObj.guid}`,
                                    }
                                    break;
                                }
                                
                                await deleteImage(dataObj.url).then((message)=>{
                                    if (message == "err") {
                                        return alert("Cannot delete the image");
                                    }
                                    info = {
                                        url:  `/v1/articles/${dataObj.guid}`,
                                    }
                                })
                                
                                break
                            case "publication": 
                                info = {
                                    url:  `/v1/blogs/publication/${dataObj.guid}`,
                                }
                                break
                            case "category": 
                                await deleteImg(dataObj.url).then((message)=>{
                                    if (message == "err") {
                                        return alert("Cannot delete the image");
                                    }
                                    info = {
                                        url:  `/v1/blogs/${dataObj.guid}`,
                                    }
                                })
                                
                                break
                            case "service": 
                                info = {
                                    url:  `/v1/services/${dataObj.guid}`,
                                }
                                break;
                        }

                        if (!info) {
                            return alert("no info");
                        }

                        async function deleteData() {
                            try {
                                const res = await axios({
                                    method: 'delete',
                                    url: info.url
                                });
                                return 1;
                            } catch (err) {
                               console.log(err); 
                               return 0;
                            }                            
                        }
                        
                        deleteData().then((res) => {
                            if (!res) {
                                return alert("Network error");
                            }
                            if (type == "user") {
                                window.location.reload();
                            }
                            if (type =! "category") {
                                document.querySelector(`.${tablename}`).innerHTML = `
                                    <img src="/img/loading.gif" alt="loading">
                                `;
                                document.querySelector(`.${tablename}`).setAttribute("status", "loading")    
                            }

                            ContentUpdate(tablename, DOMEN);
                        });

                            
            
                                          
                        
                        const delete_shadow = document.querySelector(".delete_check .shadow");

                        delete_shadow.parentElement.classList.remove("actived");

                    }

                }

                

            }
            

        }

        

    }

}