import axios from "../api/api";
import { ContentUpdate } from "./update";
import useAuth from "../hooks/useAuthContext";


export function AddData(tablename, authors) {


    const check = setInterval(() => {
        if (document.querySelector(`.${tablename}`)) {
            Lets();
            clearInterval(check);
        }
    }, 100);

    function Lets() {
        const table = document.querySelector(`.${tablename}`);
        const addPage = document.querySelector(".adding_page");
        const buttons = table.querySelectorAll(".add");
        const infoObj = {};


        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            button.onclick = () => {
                const type = button.getAttribute("type");
                const add_shadow = document.querySelector(".adding_page .shadow");

                addPage.lastElementChild.lastElementChild.querySelector(".type").innerHTML = type;

                function ImageArticle() {
                    const img = document.querySelector("#adding_img");
                    img.addEventListener("change", (event) => {
                        const [file] = event.target.files;
                        const label = document.querySelector(".adding_page .img_file");
                        const url = URL.createObjectURL(file);
                        infoObj["image"] = file;
                        if (label.classList.contains("selected")) {
                            label.querySelector(".image").src = url;
                            return;
                        }
                        label.classList.add("selected");
                        label.insertAdjacentHTML(
                            `afterbegin`,
                            `
                            <div class="photo"> 
                                <img src="${url}" class="image" alt="prevPhoto" />
                            </div>
                            `
                        )
                    })
                }

                function ImageCategory() {
                    const img = document.querySelector("#adding_img");
                    img.addEventListener("change", (event) => {
                        const [file] = event.target.files;
                        const label = document.querySelector(".adding_page .img_file");
                        const url = URL.createObjectURL(file);
                        infoObj["image"] = file;
                        if (label.classList.contains("selected")) {
                            label.querySelector(".image").src = url;
                            return;
                        }
                        label.classList.add("selected");
                        label.insertAdjacentHTML(
                            `afterbegin`,
                            `
                            <div class="photo"> 
                                <img src="${url}" class="image" alt="prevPhoto" />
                            </div>
                            `
                        )
                    })
                }

                function ImageW(count) {
                    const img = document.querySelector("#adding_img");
                    img?.addEventListener("change", (event) => {
                        const [file] = event.target.files;
                        if (!file) {
                            return;
                        }

                        const images = document.querySelector(".adding_page #media .images");
                        const url = URL.createObjectURL(file);
                        infoObj.images.push(file);
                        images.lastElementChild.insertAdjacentHTML(
                            `beforebegin`,
                            `
                            <div class="photo _${count.count}"> 
                                <img src="${url}" class="image" alt="prevPhoto" />
                                <div class="shadow delete_img"><img src="/img/delete.png" alt="delete" /></div>
                            </div>
                            `
                        );
                        DeleteImg(document.querySelector(".photo._" + count.count), infoObj.images.length - 1);
                        count.count++;
                    })
                }

                function Vid() {
                    const video = document.querySelector("#adding_video");
                    video.addEventListener("change", (event) => {
                        const [file] = event.target.files;
                        document.querySelector(".adding_page #media .video").classList.remove("plus");
                        infoObj["video"] = file;
                        if (document.querySelector(".adding_page #media .video .name")) {
                            document.querySelector(".adding_page #media .video .name").innerHTML = file.name;
                            return
                        }
                        document.querySelector(".adding_page #media .video").insertAdjacentHTML("afterbegin", `<div class="name">${event.target.files[0].name}</div>`)
                    })
                }
                function DeleteImg(element, index) {
                    const shadow = element.querySelector(".delete_img");
                    shadow.addEventListener("click", () => {
                        element.remove();
                        infoObj.images[index] = null;
                    });
                }

                function ImageAuthor() {
                    const img = document.querySelector("#adding_author_img");
                    img.addEventListener("change", (event) => {
                        const [file] = event.target.files;
                        if (!file) {
                            return;
                        }
                        const label = document.querySelector(".adding_page .img_file");
                        const url = URL.createObjectURL(file);
                        const img = new Image();

                        img.src = url;


                        img.onload = function () {
                            var height = this.height;
                            var width = this.width;
                            if (height == width && file.size < 100 * 1024) {
                                infoObj["image"] = file;
                                if (label.classList.contains("selected")) {
                                    label.querySelector(".image").src = url;
                                    return;
                                }
                                label.classList.add("selected");
                                label.insertAdjacentHTML(
                                    `afterbegin`,
                                    `
                                    <div class="photo"> 
                                        <img src="${url}" class="image" alt="prevPhoto" />
                                    </div>
                                    `
                                )
                            } else {
                                return alert("Image should be square and should not exceed the limit 100 kb");  
                            }

                        }                               
                        
                    })
                }

                switch (type) {
                    case "user":
                        addPage.setAttribute("type", "user");
                        addPage.querySelector(".inputes").classList.add("article");
                        addPage.querySelector(".inputes").innerHTML = `
                            <div class="section">
                                <label for="adding_firstname">Firstname:</label>
                                <textarea type="text" id="adding_firstname" class="required" name="firstname" ></textarea>
                            </div>
                            <div class="section">
                                <label for="adding_lastname">Lastname:</label>
                                <textarea type="text" id="adding_lastname" name="lastname" ></textarea>
                            </div>
                            <div class="section">
                                <label for="adding_role">Role:</label>
                                <select name="role" id="adding_role">
                                    <option value="dentist" selected>Dentist</option>
                                    <option value="secretary">Secretary</option>
                                </select>
                            </div>
                            <div class="section media" type="dentist" id="media">
                                <label for="adding_author_img">image:</label>

                                <label for="adding_author_img" class="img_file">
                                    <input type="file" id="adding_author_img" accept="image/*,.jpg,.png,.web" class="image" name="img" />
                                </label>

                            </div>
                            <div class="section">
                                <label for="adding_username">Username:</label>
                                <textarea type="text" id="adding_username" class="required" name="username" ></textarea>
                            </div>
                            <div class="section">
                                <label for="adding_password">Password:</label>
                                <textarea type="text" id="adding_password" class="required" name="password" ></textarea>
                            </div>
                        `;
                        const roleSelect = document.querySelector("#adding_role");
                        const media = document.getElementById("media");
                        roleSelect.addEventListener("change", () => {
                            if (roleSelect.value == "dentist") {
                                media.innerHTML = `
                                    <label for="adding_author_img">image:</label>

                                    <label for="adding_author_img" class="img_file">
                                        <input type="file" id="adding_author_img" accept="image/*,.jpg,.png,.web" class="image" name="img" />
                                    </label>
                                `;
                            } else if (roleSelect.value == "secretary") {
                                media.innerHTML = ``;
                            }
                        })

                        ImageAuthor();
                        
                        break;
                    case "article":
                        addPage.setAttribute("type", "article");
                        addPage.querySelector(".inputes").classList.add("article");
                        addPage.querySelector(".inputes").innerHTML = `
                            <div class="section">
                                <label for="adding_text">text:</label>
                                <textarea type="text" id="adding_text" class="required long" name="text" ></textarea>
                            </div>
                            <div class="part">
                                <label for="adding_img">img:</label>
                                <label for="adding_img" class="img_file">
                                    <input type="file" id="adding_img" name="img" />
                                </label>
                            </div>
                            <div class="part">
                                <label for="adding_side">side:</label>
                                <select name="side" id="adding_side">
                                    <option value="left" selected>left</option>
                                    <option value="right">right</option>
                                </select>
                            </div>
                        `;
                        ImageArticle()
                        
                        break;
                    case "category":
                        addPage.setAttribute("type", "categoryadd");
                        addPage.querySelector(".inputes").classList.add("categoryadd");
                        addPage.querySelector(".inputes").innerHTML = `
                            <div class="part">
                                <label for="adding_title" class="name">title:</label>
                                <input type="text" id="adding_title" class="required" name="title" />
                            </div>
                            <div class="part">
                                <label>img:</label>
                                <label class="img_file" for="adding_img"><input type="file" accept="image/*,.jpg,.png,.webp" id="adding_img" name="img" /></label>
                            </div>
                            <div class="part">
                                <label for="adding_description" class="name">description:</label>
                                <input type="text" id="adding_description" name="description" />
                            </div>
                        `;
                        ImageCategory()
                        
                        break;
                    case "publication":
                        const category = document.querySelector(".category.active");
                        if (!category) {
                            return alert("First, select a category");
                        }
                        addPage.setAttribute("type", "publication");
                        addPage.querySelector(".inputes").classList.add("publication");
                        addPage.querySelector(".inputes").innerHTML = `
                            <div class="part">
                                <label for="adding_title">title:</label>
                                <input type="text" id="adding_title" class="required" maxlength="100" name="title" />
                            </div>
                            <div class="part">
                                <label for="adding_type">type:</label>
                                <select id="adding_type" name="publication_type">
                                  <option value="swiper" selected>Swiper</option>
                                  <option value="video">Video</option>
                                </select>
                            </div>
                            <div class="part">
                                <label for="adding_author">author:</label>
                                <select id="adding_author" name="publication_author">
                                  
                                </select>
                            </div>
                            <div class="part media" type="swiper" id="media">
                                <label for="adding_img">images:</label>
                                <div id="adding_images" class="images">
                                    <label for="adding_img">
                                        <input type="file" id="adding_img" accept="image/*,.jpg,.png,.web" class="image" name="img" />
                                    </label>
                                </div>
                            </div>
                            <div class="part">
                                <label for="adding_text">text:</label>
                                <input type="text" id="adding_text" name="text" />
                            </div>
                        `;
                        infoObj["images"] = [];
                        const count = { count: 1 };
                        ImageW(count)
                        const select = document.getElementById("adding_type");
                        const authorSelect = document.getElementById("adding_author");
                        if (authors.role == "admin") {
                            for (let i = 0; i < authors.authors.length; i++) {
                                const author = authors.authors[i];
                                authorSelect.insertAdjacentHTML(
                                    "beforeend",
                                    `
                                        <option value="${author.guid}"> ${author.name} </option>
                                    `
                                )
                            }
                        } else if (authors.role == "dentist") {
                            authorSelect.insertAdjacentHTML(
                                "afterend",
                                `
                                    <div class="adding_author fixed_author" id="${authors.author.guid}">
                                        <div class="${authors.author?.img ? "user_ava" : `${"user_ava no_ava"}`}">
                                            ${authors.author?.img ?
                                    `<img src=${"data:photo/png;base64," + authors.author?.img} alt=""/>`
                                    :
                                    `<img src=${"/img/donut.png"} alt=""/>`
                                }               
                                        </div>
                                        ${authors.author.name} 
                                    </div>
                                `
                            )
                            authorSelect.remove();

                        }

                        select.addEventListener("change", () => {
                            if (select.value == "swiper") {
                                document.querySelector("#media").setAttribute("type", "swiper");
                                document.querySelector("#media").innerHTML = `
                                    <label for="adding_img">images:</label>
                                    <div id="adding_images" class="images">
                                    <label for="adding_img">
                                        <input type="file" id="adding_img" class="image" accept="image/*,.jpg,.png,.web" name="img" />
                                    </label>
                                    </div>
                                `
                                infoObj["images"] = [];
                                ImageW(count);
                            } else if (select.value == "video") {
                                document.querySelector("#media").setAttribute("type", "video");
                                document.querySelector("#media").innerHTML = `
                                    <label>video:</label>
                                    <label for="adding_video" class="video plus">
                                        <input type="file" id="adding_video" accept="video/*,.mp4,.mkv" name="img" />
                                    </label>
                                `;
                                infoObj["video"] = null;
                                Vid();
                            }
                        })
                        
                        
                        break;
                    case "service":
                        addPage.setAttribute("type", "article");
                        addPage.querySelector(".inputes").classList.add("article");
                        addPage.querySelector(".inputes").innerHTML = `
                            <div class="part">
                                <label for="adding_name">name:</label>
                                <input type="text" id="adding_name" class="required" maxlength="40" name="name" />
                            </div>
                            <div class="part">
                                <label for="adding_price">price:</label>
                                <input type="number" id="adding_price" class="required" name="price" />
                            </div>
                            <div class="part">
                                <label for="adding_urgent_price">urgent price:</label>
                                <input type="number" id="adding_urgent_price"np name="urgent_price" />
                            </div>
                        `;
                        break;
                    default:
                        addPage.setAttribute("type", "group");
                        addPage.querySelector(".inputes").classList.add("group");
                        addPage.querySelector(".inputes").innerHTML = `
                            <input type="text" id="adding_name" maxlength="40" name="name" />
                        `;
                        break;
                }


                

                const cancel = document.querySelector(".adding_content .cancel");
                const send = document.querySelector(".adding_content .send");

                add_shadow.parentElement.classList.add("actived");

                cancel.onclick = () => {
                    for (const key in infoObj) {
                        if (Object.hasOwnProperty.call(infoObj, key)) {
                            infoObj[key] = undefined;
                        }
                    }
                    add_shadow.parentElement.classList.remove("actived");
                    addPage.querySelector(".inputes").classList.remove("group");
                    addPage.querySelector(".inputes").classList.remove("serice");
                    addPage.querySelector(".inputes").classList.remove("article");
                }

                send.onclick = () => {
                    let info;
                    let wait = true;
                    const requiredAreas = document.querySelectorAll(".required");
                    let err = 0;
                    for (let i = 0; i < requiredAreas.length; i++) {
                        const area = requiredAreas[i];
                        if (area.value == "") {
                            return alert("Fill the required areas");
                        }
                    }

                    async function fetchImages(images) {
                        try {
                            const result = [];

                            for (let i = 0; i < images.length; i++) {
                                const img = images[i];
                                if (!img) {
                                    continue
                                }
                                const formData = new FormData();
                                formData.append("file", img);
                                const { data } = await axios({
                                    method: 'POST',
                                    url: "/v1/file",
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    },
                                    data: formData,
                                });
                                result.push({ url: data.url });
                            }

                            return result;

                        } catch (err) {
                            console.log(err);
                            return "";
                        }
                    }

                    async function fetchVideo(video) {
                        const formData = new FormData();
                        formData.append("file", video);
                        try {
                            const { data } = await axios({
                                method: 'POST',
                                url: "/v1/file",
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                                data: formData,
                            });
                            return data;

                        } catch (err) {
                            return "";
                        }
                    }
                    async function fetchIm(img) {
                        const formData = new FormData();
                        formData.append("file", img);
                        try {
                            const { data } = await axios({
                                method: 'POST',
                                url: "/v1/file",
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                },
                                data: formData,
                            });
                            return data;

                        } catch (err) {
                            return "";
                        }
                    }
                    async function fetchImg(img) {
                        const formData = new FormData();

                        formData.append("file", img);
                        try {
                            const { data } = await axios({
                                method: 'POST',
                                url: "/v1/file",
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                },
                                data: formData,
                            });
                            return data;

                        } catch (err) {
                            return "";
                        }
                    }

                    switch (type) {
                        case "user":
                            const roleSelect = document.getElementById("adding_role");
                            document.body.classList.add("loading");

                            info = {
                                url: "/v1/rbac/user",
                                data: {
                                    firstname: document.getElementById("adding_firstname").value,
                                    lastname: document.getElementById("adding_lastname").value,
                                    role: roleSelect.value,
                                    username: document.getElementById("adding_username").value,
                                    password: document.getElementById("adding_password").value
                                }
                            }
                            break
                        case "publication":
                            const select = document.getElementById("adding_type");

                            let authorid = "";

                            if (authors.role == "dentist") {
                                authorid = authors.author.guid;
                            } else {
                                authorid = document.getElementById("adding_author").value;
                            }

                            switch (select.value) {
                                case "swiper":
                                    if (!infoObj.images) {
                                        return alert("Select an img");
                                    } else if (!infoObj.images.length) {
                                        return alert("Select an img");
                                    }

                                    document.body.classList.add("loading");

                                    wait = false;



                                    fetchImages(infoObj.images).then((data) => {
                                        wait = true;

                                        document.body.classList.remove("loading");
                                        if (data == "") {
                                            return alert("Error. Cannot upload an images");
                                        }
                                        info = {
                                            url: `/v1/blogs/${document.querySelector(".category.active").getAttribute("guid")}/publication`,
                                            data: {
                                                author_id: authorid,
                                                title: document.getElementById("adding_title").value,
                                                type: "swiper",
                                                text: document.getElementById("adding_text").value,
                                                img: data,
                                                video: ""
                                            }
                                        }
                                    })

                                    

                                    break;
                                case "video":
                                    if (!infoObj.video) {
                                        return alert("Select an video");
                                    }
                                    document.body.classList.add("loading");

                                    wait = false;


                                    fetchVideo(infoObj.video).then((data) => {
                                        wait = true;

                                        document.body.classList.remove("loading");
                                        if (data == "") {
                                            return alert("Error. Cannot upload an video");
                                        }
                                        info = {
                                            url: `/v1/blogs/${document.querySelector(".category.active").getAttribute("guid")}/publication`,
                                            data: {
                                                author_id: authorid,
                                                title: document.getElementById("adding_title").value,
                                                type: "video",
                                                img: [],
                                                text: document.getElementById("adding_text").value,
                                                video: data.url
                                            }
                                        }
                                    })

                                    
                            }
                            break;
                        case "category":
                            if (!infoObj.image) {
                                return alert("Select an img");
                            }

                            wait = false;

                            fetchImg(infoObj.image).then((data) => {
                                wait = true;
                                if (data == "") {
                                    return alert("Error. Cannot upload an image");
                                }
                                info = {
                                    url: `/v1/blogs`,
                                    data: {
                                        title: document.getElementById("adding_title").value,
                                        description: document.getElementById("adding_description").value,
                                        img: data.url
                                    }
                                }
                            })

                            

                            break;
                        case "chapter":
                            info = {
                                url: `/v1/articles/chapter`,
                                data: { name: document.getElementById("adding_name").value }
                            }
                            break;
                        case "article":
                            if (!infoObj.image) {

                                info = {
                                    url: `/v1/articles`,
                                    data: {
                                        chapter_id: button.parentElement.parentElement.getAttribute("guid"),
                                        text: document.getElementById("adding_text").value,
                                        img: null,
                                        side: document.getElementById("adding_side").value
                                    }
                                }
                                break;
                            }

                            wait = false;

                            fetchIm(infoObj.image).then((data) => {
                                wait = true;

                                if (data == "") {
                                    return alert("Error. Cannot upload an image");
                                }
                                info = {
                                    url: `/v1/articles`,
                                    data: {
                                        chapter_id: button.parentElement.parentElement.getAttribute("guid"),
                                        text: document.getElementById("adding_text").value,
                                        img: data.url,
                                        side: document.getElementById("adding_side").value
                                    }
                                }
                            })

                            

                            break;
                        case "group":
                            info = {
                                url: `/v1/services/groups`,
                                data: { name: document.getElementById("adding_name").value }
                            }
                            break;
                        case "service":
                            info = {
                                url: `/v1/services`,
                                data: {
                                    group_id: button.parentElement.parentElement.getAttribute("guid"),
                                    name: document.getElementById("adding_name").value,
                                    price: Number(document.getElementById("adding_price").value),
                                    urgent_price: Number(document.getElementById("adding_urgent_price").value),
                                }
                            }
                            break;
                        default:
                            return;
                    }

                    const waiting = setInterval(() => {
                        if (wait) {
                            clearInterval(waiting)
                            if (!info) {
                                return alert("Error. NoInfo");
                            }
                            Post();

                        }

                    }, 100);







                    function Post() {
                        async function postData() {
                            try {
                                const { data } = await axios({
                                    method: 'POST',
                                    url: info.url,
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    data: JSON.stringify(info.data),
                                })

                                return data;
                            } catch (error) {
                                console.log(error);
                            }
                        }

                        postData().then((res) => {
                            if (!type == "category") {
                                document.querySelector(`.${tablename}`).innerHTML = `
                                    <img src="/img/loading.gif" alt="loading">
                                `;
                                document.querySelector(`.${tablename}`).setAttribute("status", "loading")
                            }
                            if (type == "user" && document.getElementById("adding_role").value == "dentist" && infoObj["image"]) {
                                const reader = new FileReader();


                                reader.onloadend = () => {
                                    const base64 = (reader.result.split(","))[1];
                                    setAuthorImg(base64).then(() => {
                                        document.body.classList.remove("loading");
                                        window.location.reload();
                                    });

                                }
                                reader.readAsDataURL(infoObj["image"]);

                                async function setAuthorImg(img) {
                                    try {
                                        const response = await axios({
                                            method: "PUT",
                                            url: "/v1/authors/" + res.guid,
                                            data: {
                                                img: img,
                                                name: document.querySelector("#adding_username")?.value
                                            }
                                        })
                                    } catch (err) {
                                        console.log(err);
                                    }
                                }
                            } else if (type == "user") {
                                window.location.reload();
                            }
                            ContentUpdate(tablename);
                        })
                    }






                    add_shadow.parentElement.classList.remove("actived");
                    addPage.querySelector(".inputes").classList.remove("group");
                    addPage.querySelector(".inputes").classList.remove("serice");
                    addPage.querySelector(".inputes").classList.remove("article");

                }



            }


        }



    }

}