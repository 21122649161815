
export function Js(){
    const barbuttons = document.querySelectorAll(".side_button.openbar");
    const priority2 = document.querySelectorAll(".side_button.priority-2");
    const view_shadow = document.querySelector(".view_page .shadow");
    const edit_shadow = document.querySelector(".edit_page .shadow");
    const delete_shadow = document.querySelector(".delete_check .shadow");
    const selectors = document.querySelectorAll(".selector");
    const view_close = document.querySelector(".view_content .close");
    const edit_close = document.querySelector(".edit_content .close");
    const delete_close = document.querySelector(".delete_content .close");
    const add_shadow = document.querySelector(".adding_page .shadow");
    const add_close = document.querySelector(".adding_page .close");
    const addPage = document.querySelector(".adding_page");
    
    const Blog = document.getElementById("blogs");

    
    
    view_shadow.addEventListener("click", () => {
        view_shadow.parentElement.classList.remove("actived");
    })
    view_close.addEventListener("click", () => {
        view_shadow.parentElement.classList.remove("actived");
    })
    edit_shadow.addEventListener("click", () => {
        edit_shadow.parentElement.classList.remove("actived");
    })
    edit_close.addEventListener("click", () => {
        edit_shadow.parentElement.classList.remove("actived");
    })
    delete_shadow.addEventListener("click", () => {
        delete_shadow.parentElement.classList.remove("actived");
    })
    delete_close.addEventListener("click", () => {
        delete_shadow.parentElement.classList.remove("actived");
    })
    add_shadow.addEventListener("click", () => {
        add_shadow.parentElement.classList.remove("actived");
        addPage.querySelector(".inputes").classList.remove("group");
        addPage.querySelector(".inputes").classList.remove("serice");
        addPage.querySelector(".inputes").classList.remove("article");
    })
    add_close.addEventListener("click", () => {
        add_shadow.parentElement.classList.remove("actived");
        addPage.querySelector(".inputes").classList.remove("group");
        addPage.querySelector(".inputes").classList.remove("serice");
        addPage.querySelector(".inputes").classList.remove("article");
    });

    for (let i = 0; i < priority2.length; i++) {
        const element = priority2[i];
        element.classList.remove("displayed");
    }

    for (let i = 0; i < priority2.length; i++) {
        const el = priority2[i];
        if (el.classList.contains("active")) {
            const otherchilds = document.querySelectorAll(`[buttonlink="${el.getAttribute("buttonlink")}"]`);
            document.getElementById(el.getAttribute("buttonlink")).classList.add("opened");
            for (let k = 0; k < otherchilds.length; k++) {
                const child = otherchilds[k];
                child.classList.add("displayed");
            }
        }
    }

    for (let i = 0; i < selectors.length; i++) {
        const selector = selectors[i];
        const selectorId = selector.getAttribute("item");
        selector.onclick = () => {
            const el = document.getElementById(selectorId);
            const otherchilds = document.querySelectorAll(`[buttonlink="${el.getAttribute("buttonlink")}"]`);
            document.getElementById(el.getAttribute("buttonlink")).classList.add("opened");
            for (let k = 0; k < otherchilds.length; k++) {
                const child = otherchilds[k];
                child.classList.add("displayed");
            }
        }
    }

    for (let i = 0; i < barbuttons.length; i++) {
        const button = barbuttons[i];
        button.addEventListener("click", function (props) {
            const childButtons = document.querySelectorAll(`[buttonlink="${button.id}"]`);
            for (let k = 0; k < childButtons.length; k++) {
                const childButton = childButtons[k];
                childButton.classList.toggle("displayed")
            }
            button.classList.toggle("opened");
        });
    }


}