import React from 'react';
import axios from './api/api';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/useContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
const DOMEN = "https://api.abclinic.uz";

root.render(
    <BrowserRouter>
        <AuthProvider>
            <App domen={DOMEN} />
        </AuthProvider>
    </BrowserRouter>    
);