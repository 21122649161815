import axios from "axios";

const BASE_URL = "https://api.abclinic.uz";

export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL
})

